import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './PartyPage.css';

// Import player images
import chingPowImage from '../assets/images/Players/ching pow.jpg';
import grumbusImage from '../assets/images/Players/Grumbus.jpg';
import ornyImage from '../assets/images/Players/Orny Turlcise.jpg';
import texImage from '../assets/images/Players/Tex Avery.jpg';

// Import character data service
import { useCharacterData, PARTY_MEMBERS } from '../utils/characterDataService';

// Mapping of skill codes to full names and associated abilities
const SKILL_DETAILS = {
  acr: { name: 'Acrobatics', ability: 'dex' },
  ani: { name: 'Animal Handling', ability: 'wis' },
  arc: { name: 'Arcana', ability: 'int' },
  ath: { name: 'Athletics', ability: 'str' },
  dec: { name: 'Deception', ability: 'cha' },
  his: { name: 'History', ability: 'int' },
  ins: { name: 'Insight', ability: 'wis' },
  itm: { name: 'Intimidation', ability: 'cha' },
  inv: { name: 'Investigation', ability: 'int' },
  med: { name: 'Medicine', ability: 'wis' },
  nat: { name: 'Nature', ability: 'int' },
  prc: { name: 'Perception', ability: 'wis' },
  prf: { name: 'Performance', ability: 'cha' },
  per: { name: 'Persuasion', ability: 'cha' },
  rel: { name: 'Religion', ability: 'int' },
  slt: { name: 'Sleight of Hand', ability: 'dex' },
  ste: { name: 'Stealth', ability: 'dex' },
  sur: { name: 'Survival', ability: 'wis' },
  tech: { name: 'Technology', ability: 'int' }
};

// Skill order for display
const SKILL_ORDER = [
  'acr', 'ani', 'arc', 'ath', 'dec', 'his', 'ins', 'itm', 'inv', 
  'med', 'nat', 'prc', 'prf', 'per', 'rel', 'slt', 'ste', 'sur', 'tech'
];

const PartyPage = () => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const { data: characterData, loading, error } = useCharacterData();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  // Add responsive detection
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCharacterClick = (character) => {
    setSelectedCharacter(character);
  };

  const closeModal = () => {
    setSelectedCharacter(null);
  };

  // Get character image based on id
  const getCharacterImage = (id) => {
    switch (id) {
      case 1: return chingPowImage;
      case 2: return grumbusImage;
      case 3: return ornyImage;
      case 4: return texImage;
      default: return null;
    }
  };

  // Calculate skill modifier based on ability score and proficiency
  const calculateSkillModifier = (charData, skillKey) => {
    if (!charData || !charData.abilities) return 0;
    
    const skill = charData.skills?.[skillKey];
    const abilityKey = SKILL_DETAILS[skillKey]?.ability || 'dex';
    const abilityMod = charData.abilities[abilityKey]?.modifier || 0;
    
    // If not proficient, return just the ability modifier
    if (!skill?.proficient) return abilityMod;
    
    // Calculate proficiency bonus based on level
    const level = charData.rawData?.system?.details?.level || 1;
    const profBonus = Math.floor((level - 1) / 4) + 2;
    
    // value: 1 = proficient, 2 = expertise
    const profMultiplier = skill.value || 1;
    return abilityMod + (profBonus * profMultiplier);
  };

  return (
    <div className="party-page party-tab">
      <div className="party-header">
        <h1>The Party</h1>
        {loading && <div className="loading-indicator">Loading character data...</div>}
      </div>

      <div className="party-members-container">
        {PARTY_MEMBERS.map(member => (
          <div 
            key={member.id} 
            className="party-member-card"
            onClick={() => handleCharacterClick(member)}
          >
            <div className="member-image-container">
              <img 
                src={getCharacterImage(member.id)} 
                alt={member.name} 
                className="member-image" 
              />
            </div>
            <div className="member-info">
              <h2 className="member-name">{member.name}</h2>
              <div className="member-details">
                <p className="detail-row"><span className="detail-label">LEVEL:</span> {member.level}</p>
                <p className="detail-row"><span className="detail-label">CLASS:</span> {member.class}: {member.subclass}</p>
                <p className="detail-row"><span className="detail-label">RACE:</span> {member.race}</p>
                <p className="detail-row"><span className="detail-label">HP:</span> {member.hp}</p>
                <p className="detail-row"><span className="detail-label">VEILED PATH:</span> 
                  {member.hasPathPage && member.veiledPath === 'Unbound' ? (
                    <Link 
                      to="/deities/unbound" 
                      className="path-link"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {member.veiledPath}
                    </Link>
                  ) : (
                    member.veiledPath
                  )}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedCharacter && (
        <div className="character-modal-overlay" onClick={closeModal}>
          <div className="character-modal" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal} aria-label="Close">×</button>
            
            <div className="modal-header">
              <h2>{selectedCharacter.name}</h2>
              <div className="character-subtitle">
                {`Level ${selectedCharacter.level} ${selectedCharacter.race} ${selectedCharacter.class}`}
              </div>
            </div>
            
            <div className="modal-content">
              <div className="modal-right">
                {characterData[selectedCharacter.jsonKey] ? (
                  <>
                    <div className="character-section">
                      <h3>Ability Scores</h3>
                      <div className="ability-scores">
                        {Object.entries(characterData[selectedCharacter.jsonKey].abilities || {})
                          .map(([key, ability]) => (
                          <div className="ability-score" key={key}>
                            <span className="ability-label">{key.toUpperCase()}</span>
                            <span className="ability-value">{ability.value}</span>
                            <span className="ability-modifier">
                              {ability.modifier >= 0 ? `+${ability.modifier}` : ability.modifier}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    
                    <div className="character-section">
                      <h3>Skills</h3>
                      <div className="skills-list-container">
                        {SKILL_ORDER.map(skillKey => {
                          const charData = characterData[selectedCharacter.jsonKey];
                          const skillDetails = SKILL_DETAILS[skillKey];
                          if (!charData || !skillDetails) return null;
                          
                          const isProficient = charData.skills?.[skillKey]?.proficient || false;
                          const modifier = calculateSkillModifier(charData, skillKey);
                          const formattedMod = modifier >= 0 ? `+${modifier}` : `${modifier}`;
                          
                          return (
                            <div key={skillKey} className={`skill-item ${isProficient ? 'proficient' : ''}`}>
                              <span className="skill-prof-indicator">{isProficient ? '●' : '○'}</span>
                              <span className="skill-name">{skillDetails.name} ({skillDetails.ability.toUpperCase()})</span>
                              <span className="skill-modifier">{formattedMod}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    
                    {characterData[selectedCharacter.jsonKey].currency && 
                      Object.values(characterData[selectedCharacter.jsonKey].currency || {}).some(val => val > 0) && (
                      <div className="character-section">
                        <h3>Currency</h3>
                        <div className="currency-list">
                          {Object.entries(characterData[selectedCharacter.jsonKey].currency || {}).map(([key, value]) => 
                            value > 0 ? (
                              <span key={key} className="currency-item">
                                <span className="currency-value">{value}</span>
                                <span className="currency-label">{key.toUpperCase()}</span>
                              </span>
                            ) : null
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className={loading ? "loading-data" : "error-message"}>
                    <p>{loading ? "Loading character details..." : error || "Could not load character data."}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartyPage; 