import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import nebulaImage from '../../assets/images/deities/Nebula.jpg';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const mistFlow = keyframes`
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
`;

const magicPulse = keyframes`
  0%, 100% { 
    text-shadow: 0 0 20px rgba(220, 20, 60, 0.8),
                 0 0 40px rgba(220, 20, 60, 0.4),
                 0 0 60px rgba(220, 20, 60, 0.2);
  }
  50% { 
    text-shadow: 0 0 30px rgba(220, 20, 60, 0.9),
                 0 0 50px rgba(220, 20, 60, 0.5),
                 0 0 70px rgba(220, 20, 60, 0.3);
  }
`;

const moonGlow = keyframes`
  0%, 100% { filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.8)); }
  50% { filter: drop-shadow(0 0 25px rgba(255, 255, 255, 0.4)); }
`;

const StyledMistPage = styled.div`
  background: #0a0a0a;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      linear-gradient(
        135deg,
        rgba(10, 10, 10, 0.97),
        rgba(40, 0, 0, 0.95)
      ),
      radial-gradient(
        circle at center,
        rgba(220, 20, 60, 0.1),
        rgba(0, 0, 0, 0.98)
      );
    background-size: 400% 400%;
    animation: ${mistFlow} 15s ease infinite;
    z-index: -1;
  }

  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #DC143C, #FF69B4, #DC143C);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    animation: ${magicPulse} 3s ease-in-out infinite;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    &::after {
      content: '☽';
      position: absolute;
      top: -0.5em;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.5em;
      color: #fff;
      animation: ${moonGlow} 2s ease-in-out infinite;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(220, 20, 60, 0.3);
    box-shadow: 
      0 0 20px rgba(220, 20, 60, 0.1),
      inset 0 0 30px rgba(220, 20, 60, 0.05);
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
    margin: 2rem 0;
    padding: 2rem;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(45deg,
          transparent,
          rgba(220, 20, 60, 0.05),
          transparent
        );
      background-size: 200% 200%;
      animation: ${mistFlow} 3s linear infinite;
      pointer-events: none;
    }

    &:hover {
      border-color: rgba(220, 20, 60, 0.6);
      box-shadow: 
        0 0 30px rgba(220, 20, 60, 0.2),
        inset 0 0 50px rgba(220, 20, 60, 0.1);
      transform: translateY(-2px);
    }
  }

  h2 {
    color: #DC143C;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(220, 20, 60, 0.5);
    margin-bottom: 1rem;
  }

  h3 {
    color: #FF69B4;
    font-size: 1.5rem;
    text-shadow: 0 0 8px rgba(255, 105, 180, 0.4);
    margin: 1rem 0;
  }

  p {
    color: #E0E0E0;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  }

  strong {
    color: #FF69B4;
    text-shadow: 0 0 5px rgba(255, 105, 180, 0.3);
    font-weight: bold;
  }

  ul li {
    color: #E0E0E0;
    position: relative;
    
    &::before {
      content: '✧';
      color: #DC143C;
      position: absolute;
      left: -1.5rem;
      text-shadow: 0 0 5px rgba(220, 20, 60, 0.5);
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #DC143C, #FF69B4);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 0 0 10px rgba(220, 20, 60, 0.5);
    }
  }

  .veiled-paths h2 {
    color: #DC143C;
    text-shadow: 0 0 10px rgba(220, 20, 60, 0.5);
  }

  .veiled-link {
    color: #E0E0E0;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: #FF69B4;
      text-shadow: 0 0 8px rgba(255, 105, 180, 0.4);
    }
  }

  .image-modal {
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);

    .modal-image {
      box-shadow: 0 0 30px rgba(220, 20, 60, 0.2);
    }

    .modal-close,
    .modal-download {
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid rgba(220, 20, 60, 0.3);
      color: #DC143C;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(220, 20, 60, 0.1);
        border-color: #DC143C;
      }
    }
  }

  .deity-path-background {
    filter: brightness(0.9) contrast(1.1) saturate(1.2);
    transition: filter 0.3s ease;

    &:hover {
      filter: brightness(1) contrast(1.2) saturate(1.3);
    }
  }

  .veiled-paths {
    position: relative;
    display: inline-block;
  }
  
  .veiled-links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 }
};

const contentVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.7, ease: "easeOut" }
};

const traitVariants = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5, ease: "easeOut" }
};

const PantheonNebula = () => {
  const [showFullImage, setShowFullImage] = useState(false);

  return (
    <StyledMistPage>
      <motion.div 
        className="deity-path-page"
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.div 
          className="deity-path-hero"
          variants={contentVariants}
        >
          <img 
            src={nebulaImage} 
            alt="Nebula: The Veiled One" 
            className="deity-path-background"
            onClick={() => setShowFullImage(true)}
            style={{ cursor: 'pointer' }}
          />
          <motion.div 
            className="deity-path-title"
            variants={contentVariants}
          >
            <h1>Nebula: The Veiled One</h1>
          </motion.div>
        </motion.div>

        <motion.div 
          className="content-block dark-block"
          variants={contentVariants}
        >
          <h2 className="blue-text">The Night Mist</h2>
          <p>
            Nebula, the Veiled One, is the enigmatic deity of magic in Velum. Their presence, a subtle veil of mist visible only at night, cloaks the realm, subtly influencing the ebb and flow of magical energies. This mist, an ethereal presence that interacts with the world's elements without disrupting them, collects and channels ambient energies, weaving them into the very essence of magic that permeates Velum.
          </p>
          <p>
            Nebula's power is immense, drawn from the subtle energies that permeate the world. Some speculate that their very essence is intertwined with the Weave of magic, allowing them to shape and channel its power in ways that other deities cannot.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>The First Veil</h3>
          </div>
          <p>
            Nebula is credited with the creation of the First Veil, a colossal wall that safeguards Velum from a devastating threat to the south. This barrier is guarded by Nebula's clergy, figures clad in crimson robes who wield formidable magic, ensuring the safety of those who dwell north of the wall.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Magic</h3>
          </div>
          <p>
            Nebula, the architect of magic, shapes the realm from the shadows, their power vast and enigmatic. Unlike deities who revel in worship, Nebula's influence is subtle, their motives often inscrutable.
          </p>
          <p>
            It is believed that Nebula's magic is cyclical, waxing and waning with the phases of the moon. When the moon is full, their power is at its zenith, and magic surges through the land. As the moon wanes, so too does their influence, creating a natural ebb and flow to the arcane energies that permeate Velum.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <h2>Divine Aspects</h2>
          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Veiled One, The Night Mist</p>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Domain</h3>
            </div>
            <p>Magic</p>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A crescent moon veiled in swirling mist</p>
          </motion.div>
        </motion.div>

        {showFullImage && (
          <motion.div 
            className="image-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowFullImage(false)}
          >
            <motion.div 
              className="modal-image-container"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
            >
              <img 
                src={nebulaImage} 
                alt="Nebula: The Veiled One"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={nebulaImage}
                download="Nebula.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </StyledMistPage>
  );
};

export default PantheonNebula; 