import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import titaniamImage from '../../assets/images/deities/sunburstwildgrowth.jpg';
import './DeityPathPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const fireGlow = keyframes`
  0%, 100% { text-shadow: 0 0 30px #FF4500, 0 0 50px #FF4500, 0 0 70px #FF4500; }
  50% { text-shadow: 0 0 20px #FF8C00, 0 0 35px #FF8C00, 0 0 50px #FF8C00; }
`;

const wildGrowth = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  25% { transform: scale(1.02) rotate(0.5deg); }
  50% { transform: scale(1) rotate(-0.5deg); }
  75% { transform: scale(1.02) rotate(0.5deg); }
  100% { transform: scale(1) rotate(0deg); }
`;

const flameFlicker = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.8; }
`;

const StyledWildgrowthPage = styled.div`
  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #FF4500, #FF8C00, #32CD32);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    animation: ${fireGlow} 3s ease-in-out infinite;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, 
        transparent,
        rgba(255, 69, 0, 0.2),
        rgba(50, 205, 50, 0.2),
        transparent
      );
      background-size: 200% 100%;
      animation: ${wildGrowth} 5s ease-in-out infinite;
      z-index: -1;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(255, 69, 0, 0.3);
    box-shadow: 0 0 20px rgba(255, 69, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(135deg,
          transparent,
          rgba(255, 69, 0, 0.05) 40%,
          rgba(50, 205, 50, 0.05) 60%,
          transparent
        );
      animation: ${flameFlicker} 3s infinite;
      pointer-events: none;
    }

    &:hover {
      border-image: linear-gradient(45deg, #FF4500, #32CD32) 1;
      box-shadow: 
        0 0 20px rgba(255, 69, 0, 0.2),
        0 0 30px rgba(50, 205, 50, 0.1);
      transform: translateY(-2px);
    }
  }

  h2 {
    color: #FF4500;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(255, 69, 0, 0.5);
    margin-bottom: 1rem;
    
    &:hover {
      animation: ${fireGlow} 1.5s ease-in-out infinite;
    }
  }

  h3 {
    color: #FF8C00;
    font-size: 1.5rem;
    text-shadow: 0 0 8px rgba(255, 140, 0, 0.4);
    margin: 1rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      color: #FF4500;
      text-shadow: 0 0 12px rgba(255, 69, 0, 0.6);
    }
  }

  p {
    color: #FFF;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  }

  strong {
    background: linear-gradient(45deg, #FF4500, #32CD32);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    padding: 0 2px;
  }

  ul li {
    color: #FFF;
    position: relative;
    
    &::before {
      color: #32CD32 !important;
      text-shadow: 0 0 5px rgba(50, 205, 50, 0.5);
    }

    &:hover::before {
      color: #FF4500 !important;
      text-shadow: 0 0 5px rgba(255, 69, 0, 0.5);
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #FF4500, #32CD32);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 
        0 0 10px rgba(255, 69, 0, 0.5),
        0 0 20px rgba(50, 205, 50, 0.3);
    }
  }

  .veiled-paths h2 {
    background: linear-gradient(45deg, #FF4500, #32CD32);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
  }

  .veiled-link {
    color: #FFF !important;
    transition: all 0.3s ease;

    &:hover {
      color: #FF4500 !important;
      text-shadow: 0 0 8px rgba(255, 69, 0, 0.4);
    }
  }

  .requirements-section {
    border-image: linear-gradient(45deg, #FF4500, #32CD32) 1;
    animation: ${wildGrowth} 8s ease-in-out infinite;
  }
`;

const QueenTitaniaWildgrowth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // The sequence of pages for navigation - following dropdown menu order
  const pageSequence = [
    '/deities/caelumbris/shadowed',
    '/deities/firstborn/soulbound',
    '/deities/libris/knowledge',
    '/deities/nebula/mistwalker',
    '/deities/queenmab/frozenveil',
    '/deities/queen-titania/wildgrowth',
    '/deities/sacrathor/dominion',
    '/deities/unbound',
    '/deities/valdis/justice',
    '/deities/wildscar/emberfury'
  ];

  // Required minimum distance traveled to be considered a swipe
  const minSwipeDistance = 50;

  // Handle navigation based on swipe direction
  const handleSwipe = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    // Get current page index in the sequence
    const currentIndex = pageSequence.findIndex(page => page === location.pathname);
    
    // Navigate based on swipe direction
    if (isLeftSwipe) {
      // Navigate to next page (or first if at end)
      const nextIndex = (currentIndex + 1) % pageSequence.length;
      navigate(pageSequence[nextIndex]);
    } else if (isRightSwipe) {
      // Navigate to previous page (or last if at first)
      const prevIndex = (currentIndex - 1 + pageSequence.length) % pageSequence.length;
      navigate(pageSequence[prevIndex]);
    }
  };

  // Touch event handlers
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
  };

  // Mouse event handlers for desktop testing
  const onMouseDown = (e) => {
    setTouchEnd(null);
    setTouchStart(e.clientX);
  };

  const onMouseMove = (e) => {
    if (!touchStart) return;
    setTouchEnd(e.clientX);
  };

  const onMouseUp = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
    setTouchStart(null);
    setTouchEnd(null);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return (
    <StyledWildgrowthPage 
      className="deity-path-page titania-page"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <div className="deity-path-hero">
        <img 
          src={titaniamImage} 
          alt="Queen Titania: Wildgrowth Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Queen Titania: The Wildgrowth Path</h1>
        </div>
      </div>

      <div className="content-block requirements-section">
        <div className="edicts">
          <h2>Edicts</h2>
          <ul>
            <li><strong>Embrace Life's Energy:</strong> Embrace the boundless energy of life, recognizing that growth and change are essential, even if they bring chaos.</li>
            <li><strong>Defend and Restore:</strong> Use nature's power to defend the weak and reclaim corrupted or blighted lands.</li>
            <li><strong>Channel Summer's Force:</strong> Channel the energy of summer in both creation and destruction, recognizing the cyclical nature of life and renewal.</li>
          </ul>
        </div>
        
        <div className="requirements">
          <h2>Requirements</h2>
          <ul>
            <li><strong>Wild Growth:</strong> Followers must nurture and channel wild growth, embracing unrestrained passion or creation that mirrors the untamed energy of summer.</li>
            <li><strong>Winter's Ban:</strong> They may not touch ice, use cold spells, or harness the energy of winter in any form.</li>
            <li><strong>Passionate Expression:</strong> Express your emotions freely and authentically, allowing your passions to guide your actions.</li>
          </ul>
        </div>
      </div>

      <div className="content-block dark-block">
        <h2 className="blue-text">Queen Titania: The Summer Queen</h2>
        <p>
          Queen Titania reigns as a deity of unrelenting growth and passionate energy. Her domain is an endless expanse of vibrant meadows, ancient forests, and sun-drenched hills, teeming with life and brimming with untamed magic. Where Titania's power touches, nature thrives—sometimes to the brink of chaos. Titania's rule radiates boundless energy and untamed love, a reflection of nature's vibrant force at its peak. Her followers see her as the embodiment of creation's joy tempered by nature's destructive cycles. Her court is a kaleidoscope of endless celebration and flourishing beauty, where wild dances and blazing bursts of magic echo her exuberant reign. Through her, practitioners learn to embrace the raw, transformative energy of summer.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Queen Titania's Domain</h3>
        </div>
        <p>
          Titania's realm is a land of boundless vitality and radiant splendor. Vast fields of wildflowers shimmer like liquid sunlight, while towering trees hum with fey energy. Golden streams wind through fertile valleys under a sun that never sets, bathing everything in eternal warmth.
        </p>
        <p>
          Yet even here, wild energy courts chaos—unchecked vines may choke mighty oaks, and spontaneous bursts of flame disrupt the landscape. This is the paradox of Titania's domain: a place where exuberant beauty and fierce destruction exist in eternal balance.
        </p>
      </div>

      <h2>Levels and Abilities</h2>
      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 3: WILDFLOWER INITIATE</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Core Features</h3>
          </div>
          <ul className="specializations-list">
            <li><strong>Fire Resistance:</strong> The scorching energy of the summer at your core grants you resistance to fire damage.</li>
            <li><strong>Charisma Increase:</strong> +1 to Charisma (maximum Charisma increases by 1, raising the cap to 21).</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Chaotic Bloom</h3>
          </div>
          <ul className="specializations-list">
            <li><strong>Passive:</strong> Nature surges around you. Plants grow in a 1-foot radius per level, mimicking the Plant Growth spell. Your bond with this energy grants you +1 Charisma.</li>
            <li><strong>Active:</strong> As an action, unleash a burst of wild, chaotic energy in a 30-foot radius centered on you. Each creature in the area must roll on the Summer Fey Chaos Table and make a Dexterity saving throw (DC = 8 + your proficiency bonus + your Charisma modifier). On a failed save, they take 1d10 fire damage in addition to the effect from the table. On a successful save, they take half damage and suffer no additional effects.</li>
            <li><em>Usage: A number of times equal to your proficiency bonus per long rest.</em></li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Summer Fey Chaos Table (1d10)</h3>
          </div>
          <ul className="specializations-list">
            <li><strong>1. Vivid Hallucinations:</strong> The creature sees shifting patterns and becomes frightened of the largest or nearest creature.</li>
            <li><strong>2. Lush Growth:</strong> Uncontrolled vines sprout from the creature, halving their movement speed until the end of their next turn.</li>
            <li><strong>3. Warm Delight:</strong> The creature is charmed by you (Wisdom save negates, DC as above).</li>
            <li><strong>4. Pollen Cloud:</strong> A thick pollen cloud blinds the creature (Constitution save negates).</li>
            <li><strong>5. Nauseating Pollen:</strong> The creature is poisoned and loses reactions.</li>
            <li><strong>6. Golden Glow:</strong> The creature becomes outlined in radiant light, suffering disadvantage on attack rolls, skill checks, and saving throws.</li>
            <li><strong>7. Chaotic Teleportation:</strong> At the start of their turn, they randomly teleport 5 feet in a random direction (if no space, they take 1d4 force damage).</li>
            <li><strong>8. Fey Laughter:</strong> The creature bursts into uncontrollable laughter, incapacitated until the end of its next turn.</li>
            <li><strong>9. Maddening Heat:</strong> The creature is overwhelmed by burning passion and becomes enraged, attacking the nearest creature.</li>
            <li><strong>10. Nature's Embrace:</strong> Vines entangle the creature, restraining them (Strength save negates).</li>
          </ul>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 5: VERDANT RESURGENCE</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Core Features</h3>
          </div>
          <ul className="specializations-list">
            <li><strong>Radiant Resistance:</strong> You gain resistance to radiant damage, as your body begins to radiate the untamed light of summer.</li>
            <li><strong>Charisma Increase:</strong> +1 to Charisma (maximum Charisma increases by 1, raising the cap to 22).</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Nature's Rebirth</h3>
          </div>
          <p>When you are reduced to 0 HP, you release a surge of untamed energy. Creatures within range take fire damage, and you instantly recover to 1 HP, moving without provoking attacks.</p>
          <p><em>Usage: Once per long rest.</em></p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Radiant Bloom</h3>
          </div>
          <p>Your Chaotic Bloom (Active) gains new effects:</p>
          <ul className="specializations-list">
            <li>Creatures failing their save take extra radiant damage.</li>
            <li>One targeted creature is rooted, reducing movement speed to 0.</li>
          </ul>
          <p><em>Usage: Proficiency bonus per long rest.</em></p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 10: SUNLIT CANOPY & WILDGROWTH STRIDE</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Core Features</h3>
          </div>
          <ul className="specializations-list">
            <li><strong>Charisma Increase:</strong> +1 to Charisma (maximum Charisma increases by 1, raising the cap to 23).</li>
            <li><strong>Eternal Light – Sunlit Canopy:</strong> You radiate permanent daylight in a 30-foot radius, extending to dim light beyond. This natural light cannot be dispelled. Creatures reliant on darkness are weakened within your glow.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Wildgrowth Stride</h3>
          </div>
          <ul className="specializations-list">
            <li>Increase speed by 20 feet.</li>
            <li>Ignore difficult terrain created by plant life.</li>
            <li>As a bonus action, traverse water and vertical surfaces for up to 10 feet.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Scorching Vines</h3>
          </div>
          <p>You conjure lashing vines infused with searing fire and radiant energy. As an action, you unleash three fiery vine projectiles, each targeting a creature within 60 feet. You make a ranged spell attack for each vine separately, using your Charisma modifier for the attack roll.</p>
          <ul className="specializations-list">
            <li><strong>On Hit:</strong> The target takes 1d10 fire damage + 1d10 radiant damage, and must make a Strength saving throw (DC = 8 + your proficiency bonus + your Charisma modifier) or be grappled until the end of your next turn.</li>
            <li><strong>Grapple:</strong> Grappled creatures are restrained and cannot move until they succeed on a Strength check to break free (DC = your spell save DC).</li>
            <li><strong>Targeting:</strong> You may direct all vines at a single target or multiple targets within range.</li>
          </ul>
          <p><em>Usage: A number of times equal to your proficiency bonus per long rest.</em></p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 15: VERDANT COLOSSUS – THE BLOOMING GUARDIAN</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Core Features</h3>
          </div>
          <ul className="specializations-list">
            <li><strong>Charisma Increase:</strong> +1 to Charisma (maximum Charisma increases by 1, raising the cap to 24).</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Transformation</h3>
          </div>
          <p>As an action, assume the form of the Blooming Guardian, an awe-inspiring Treant imbued with the radiance of Titania's domain. Your body expands into a towering figure of living wood and golden leaves, shimmering with the light of an eternal summer. This transformation lasts until you choose to revert as a bonus action.</p>
          <ul className="specializations-list">
            <li><strong>Titanic Strength:</strong> Your melee attacks deal 2d8 radiant damage and count as magical.</li>
            <li><strong>Verdant Canopy:</strong> Your Eternal Light radius doubles (60 ft bright, 60 ft dim).</li>
            <li><strong>Unyielding Growth:</strong> Gain +30 HP and resistance to non-magical bludgeoning, slashing, and piercing damage.</li>
            <li><strong>Living Fortress:</strong> Increase AC by +2 as your form hardens with nature's power.</li>
            <li><strong>Flourishing Renewal:</strong> If you complete a short or long rest in true sunlight, you restore all hit points, fully rejuvenated by the power of summer.</li>
            <li><strong>Titanic Presence:</strong> Allies within 30 feet gain temporary HP equal to your proficiency bonus at the start of their turn.</li>
          </ul>
        </div>
      </div>

      <div className="content-block">
        <h2>Roleplaying the Path of Summer</h2>
        <p>
          Followers of Queen Titania are defined by their untamed passion and boundless energy. They thrive in chaos, wielding the vibrant intensity of summer to reshape the world around them. As living extensions of Titania's will, they revel in both creation and destruction, finding beauty in the wild, unrestrained power of nature.
          Choosing this path is a commitment to embrace life in its fullest, wildest form. Practitioners of the Summer Path embody the warmth and ferocity of the sun, spreading Titania's chaotic bloom wherever they go and leaving trails of radiant growth and fiery renewal in their wake.
        </p>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={titaniamImage} 
              alt="Queen Titania: Wildgrowth Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={titaniamImage}
              download="Titania.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </StyledWildgrowthPage>
  );
};

export default QueenTitaniaWildgrowth;