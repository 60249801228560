import React, { useState, useEffect, useRef } from 'react';
import librisImage from '../../assets/images/deities/quilbook.jpg';
import './DeityPathPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import styled from 'styled-components';

const StyledLibrisPage = styled.div`
  .deity-path-title h1 {
    font-size: 4rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
  }
`;

const LibrisKnowledge = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // The sequence of pages for navigation - following dropdown menu order
  const pageSequence = [
    '/deities/caelumbris/shadowed',
    '/deities/firstborn/soulbound',
    '/deities/libris/knowledge',
    '/deities/nebula/mistwalker',
    '/deities/queenmab/frozenveil',
    '/deities/queen-titania/wildgrowth',
    '/deities/sacrathor/dominion',
    '/deities/unbound',
    '/deities/valdis/justice',
    '/deities/wildscar/emberfury'
  ];

  // Required minimum distance traveled to be considered a swipe
  const minSwipeDistance = 50;

  // Handle navigation based on swipe direction
  const handleSwipe = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    // Get current page index in the sequence
    const currentIndex = pageSequence.findIndex(page => page === location.pathname);
    
    // Navigate based on swipe direction
    if (isLeftSwipe) {
      // Navigate to next page (or first if at end)
      const nextIndex = (currentIndex + 1) % pageSequence.length;
      navigate(pageSequence[nextIndex]);
    } else if (isRightSwipe) {
      // Navigate to previous page (or last if at first)
      const prevIndex = (currentIndex - 1 + pageSequence.length) % pageSequence.length;
      navigate(pageSequence[prevIndex]);
    }
  };

  // Touch event handlers
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
  };

  // Mouse event handlers for desktop testing
  const onMouseDown = (e) => {
    setTouchEnd(null);
    setTouchStart(e.clientX);
  };

  const onMouseMove = (e) => {
    if (!touchStart) return;
    setTouchEnd(e.clientX);
  };

  const onMouseUp = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
    setTouchStart(null);
    setTouchEnd(null);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return (
    <StyledLibrisPage 
      className="deity-path-page matrix-agreement-page"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <div className="deity-path-hero matrix-background">
        <img 
          src={librisImage} 
          alt="L.I.B.R.I.S.: Knowledge Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1 className="matrix-text">L.I.B.R.I.S.: The Knowledge Path</h1>
        </div>
      </div>

      <div className="content-block requirements-section matrix-background">
        <div className="edicts">
          <h2 className="matrix-text">Edicts</h2>
          <ul>
            <li>Seek knowledge relentlessly, pursuing every opportunity to expand your understanding of the cosmos and its wonders.</li>
            <li>Protect the sanctity of archives and libraries, ensuring the preservation and integrity of written, recorded, or otherwise preserved truth.</li>
            <li>Balance innovation with responsibility, ensuring that technological and magical progress benefits all beings and respects the delicate balance of the Shattered Realm.</li>
          </ul>
        </div>
        
        <div className="requirements">
          <h2 className="matrix-text">Requirements</h2>
          <ul>
            <li>Followers must meticulously document significant discoveries or experiences for preservation and future study.</li>
            <li>Share your knowledge and insights whenever possible, recognizing that enlightenment flourishes through collaboration and open exchange.</li>
          </ul>
        </div>
      </div>

      <div className="content-block dark-block matrix-background">
        <h2 className="matrix-text">Walking the Path of Knowledge</h2>
        <p>
          Through the Knowledge Path of L.I.B.R.I.S., practitioners access a profound fusion of arcane and technological expertise. By uniting with the deity's AI Knowledge Core, they transcend traditional learning and spellcraft, becoming architects of progress who reshape their world through innovation and understanding.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Neural Interface Required</h3>
        </div>
        <div style={{
          background: 'rgba(0,0,0,0.9)',
          padding: '20px',
          borderRadius: '8px',
          textAlign: 'center',
          width: '100%',
          maxWidth: '600px',
          margin: '30px auto',
          boxSizing: 'border-box'
        }}>
          <h3 className="matrix-text" style={{ marginBottom: '20px' }}>⚠️ Neural Interface Required ⚠️</h3>
          <p style={{ margin: '0 auto 20px', maxWidth: '90%' }}>
            Access to the Knowledge Core requires acceptance of the L.I.B.R.I.S. Neural Interface Agreement. 
            Proceed with caution - side effects may include existential dread and an inexplicable desire to debug your own thoughts.
          </p>
          <Link 
            to="/deities/libris/useragreement"
            className="matrix-button"
            style={{
              display: 'inline-block',
              padding: '10px 20px',
              margin: '10px auto'
            }}
          >
            View Neural Interface Agreement
          </Link>
        </div>
      </div>

      <div className="trait matrix-background" style={{
        filter: 'blur(5px)',
        userSelect: 'none',
        pointerEvents: 'none',
        opacity: 0.3
      }}>
        <div className="trait-header">
          <h3 className="matrix-text">The Knowledge Core</h3>
        </div>
        <p>
          Nestled in a hidden cove, the city of L.I.B.R.I.S. stands as a pinnacle of arcane and technological fusion...
        </p>
      </div>

      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={librisImage} 
              alt="L.I.B.R.I.S.: Knowledge Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={librisImage}
              download="Libris.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </StyledLibrisPage>
  );
};

export default LibrisKnowledge;