import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { 
  GiGreekTemple, 
  GiCrownOfThorns, 
  GiCastle,
  GiSpellBook,
  GiDna1,
  GiCrossMark
} from 'react-icons/gi';
import styled from 'styled-components';

// Fixed bottom navigation bar
const NavBar = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, #1a1a1a, #0d0d0d);
  border-top: 1px solid rgba(193, 154, 107, 0.3);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7);
  z-index: 998;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
`;

// Navigation button
const NavButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.isActive ? '#ffd700' : '#fff'};
  transition: all 0.2s ease;
  cursor: pointer;
  
  &:hover {
    color: #ffd700;
  }
  
  .fantasy-icon {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
  }
`;

// Button label
const ButtonLabel = styled.span`
  font-size: 0.8rem;
  margin-top: 4px;
  font-family: 'Cinzel', serif;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

// Fullscreen menu overlay
const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  z-index: 999;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(100%)'};
  overflow-y: auto;
`;

// Menu header
const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(193, 154, 107, 0.3);
  background: linear-gradient(to bottom, #1a1a1a, #0d0d0d);
  position: sticky;
  top: 0;
`;

// Menu title
const MenuTitle = styled.h2`
  margin: 0;
  color: #ffd700;
  font-size: 1.8rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Cinzel', serif;
`;

// Close button
const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #ffd700;
  }
`;

// Menu content
const MenuContent = styled.div`
  padding: 30px 20px;
  flex: 1;
`;

// Menu list
const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-width: 600px;
  margin: 0 auto;
`;

// Menu item
const MenuItem = styled.li`
  margin-bottom: 15px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  a {
    display: block;
    padding: 16px 20px;
    background: rgba(193, 154, 107, 0.1);
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    transition: all 0.2s ease;
    font-size: 1.3rem;
    text-align: center;
    
    &:hover {
      background: rgba(193, 154, 107, 0.2);
      color: #ffd700;
    }
  }
`;

const Navigation = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  // Check if user has sufficient role
  const hasAccess = currentUser && 
    (currentUser.role === 'adventurer' || currentUser.role === 'admin');
  
  const handleMenuToggle = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };
  
  const handleNavigation = (path) => {
    if (!hasAccess && path.startsWith('/deity')) {
      alert("You need to be an Adventurer or Admin to access this content.");
      return;
    }
    
    navigate(path);
    setActiveMenu(null);
  };
  
  // Define menu data
  const menuData = {
    firstVeil: {
      title: "First Veil",
      icon: <GiCastle size={24} className="fantasy-icon" />,
      paths: [
        { name: "Profile", path: "/dashboard" },
        { name: "Help", path: "/help" },
        { name: "History", path: "/history" },
        { name: "House Rules", path: "/house-rules" }
      ]
    },
    classes: {
      title: "Classes",
      icon: <GiSpellBook size={24} className="fantasy-icon" />,
      paths: [
        { name: "Circle of the Burning Seed", path: "/classes/ClassesBurningSeed" },
        { name: "Dronewright", path: "/classes/ClassesDronewright" },
        { name: "Circle of the Glacial Heart", path: "/classes/ClassesGlacialHeart" },
        { name: "Path of Wrath", path: "/classes/ClassesPathOfWrath" },
        { name: "Pyromancer", path: "/classes/ClassesPyromancer" },
        { name: "Seraphic Guardian", path: "/classes/ClassesSeraphicGuardian" },
        { name: "Sovereign Zealot", path: "/classes/ClassesSovereignZealot" },
        { name: "Umbral Infiltrator", path: "/classes/ClassesUmbralInfiltrator" },
        { name: "Way of the Lifedrinker", path: "/classes/ClassesWayOfTheLifedrinker" },
        { name: "Winterborn Knight", path: "/classes/ClassesWinterbornKnight" }
      ]
    },
    species: {
      title: "Species",
      icon: <GiDna1 size={24} className="fantasy-icon" />,
      paths: [
        { name: "Gravewalker", path: "/species/SpeciesGravewalker" },
        { name: "Humanity", path: "/species/SpeciesHumanity" },
        { name: "Lifedrinker", path: "/species/SpeciesLifedrinker" },
        { name: "Manaforged", path: "/species/SpeciesManaforged" },
        { name: "Scytherai", path: "/species/SpeciesScytherai" },
        { name: "Seraphiend", path: "/species/SpeciesSeraphiend" },
        { name: "Skinwalker", path: "/species/SpeciesSkinWalker" },
        { name: "Starborn", path: "/species/SpeciesStarborn" },
        { name: "Sunseed", path: "/species/SpeciesSunseed" },
        { name: "Vermis Corpus", path: "/species/SpeciesVermisCorpus" },
        { name: "Winter Witch", path: "/species/SpeciesWinterWitch" }
      ]
    },
    deity: {
      title: "Veiled Paths",
      icon: <GiCrownOfThorns size={24} className="fantasy-icon" />,
      paths: [
        { name: "Caelumbris Shadowed", path: "/deity/caelumbris-shadowed" },
        { name: "Libris Knowledge", path: "/deity/libris-knowledge" },
        { name: "Firstborn Soulbound", path: "/deity/firstborn-soulbound" },
        { name: "Nebula Mist Walker", path: "/deity/nebula-mist-walker" },
        { name: "Queen Mab Frozen Veil", path: "/deity/queen-mab-frozen-veil" },
        { name: "Queen Titania Wildgrowth", path: "/deity/queen-titania-wildgrowth" },
        { name: "Sacrathor Dominion", path: "/deity/sacrathor-dominion" },
        { name: "Unbound", path: "/deity/unbound" },
        { name: "Valdis Justice", path: "/deity/valdis-justice" },
        { name: "Wildscar Ember Fury", path: "/deity/wildscar-ember-fury" }
      ]
    },
    pantheon: {
      title: "Pantheons",
      icon: <GiGreekTemple size={24} className="fantasy-icon" />,
      paths: [
        { name: "Caelumbris", path: "/pantheon/caelumbris" },
        { name: "Calanthar", path: "/pantheon/calanthar" },
        { name: "Leviathan", path: "/pantheon/leviathan" },
        { name: "Firstborn", path: "/pantheon/firstborn" },
        { name: "Libris", path: "/pantheon/libris" },
        { name: "Nebula", path: "/pantheon/nebula" },
        { name: "Queen Mab", path: "/pantheon/queen-mab" },
        { name: "Queen Titania", path: "/pantheon/queen-titania" },
        { name: "Sacrathor", path: "/pantheon/sacrathor" },
        { name: "Valdis", path: "/pantheon/valdis" },
        { name: "Wildscar", path: "/pantheon/wildscar" }
      ]
    }
  };
  
  return (
    <>
      {/* Navigation bar with icons */}
      <NavBar>
        <NavButton 
          isActive={activeMenu === 'firstVeil'}
          onClick={() => handleMenuToggle('firstVeil')}
        >
          {menuData.firstVeil.icon}
          <ButtonLabel>First Veil</ButtonLabel>
        </NavButton>
        
        <NavButton 
          isActive={activeMenu === 'classes'}
          onClick={() => handleMenuToggle('classes')}
        >
          {menuData.classes.icon}
          <ButtonLabel>Classes</ButtonLabel>
        </NavButton>
        
        <NavButton 
          isActive={activeMenu === 'species'}
          onClick={() => handleMenuToggle('species')}
        >
          {menuData.species.icon}
          <ButtonLabel>Species</ButtonLabel>
        </NavButton>
        
        {hasAccess && (
          <>
            <NavButton 
              isActive={activeMenu === 'deity'}
              onClick={() => handleMenuToggle('deity')}
            >
              {menuData.deity.icon}
              <ButtonLabel>Veiled Paths</ButtonLabel>
            </NavButton>
            
            <NavButton 
              isActive={activeMenu === 'pantheon'}
              onClick={() => handleMenuToggle('pantheon')}
            >
              {menuData.pantheon.icon}
              <ButtonLabel>Pantheons</ButtonLabel>
            </NavButton>
          </>
        )}
      </NavBar>
      
      {/* Menu overlay for each section */}
      {Object.keys(menuData).map(key => (
        hasAccess || (key !== 'deity' && key !== 'pantheon') ? (
          <MenuOverlay key={key} isOpen={activeMenu === key}>
            <MenuHeader>
              <MenuTitle>{menuData[key].title}</MenuTitle>
              <CloseButton onClick={() => setActiveMenu(null)}>
                <GiCrossMark />
              </CloseButton>
            </MenuHeader>
            
            <MenuContent>
              <MenuList>
                {menuData[key].paths.map((item, index) => (
                  <MenuItem key={index}>
                    <a onClick={() => handleNavigation(item.path)}>
                      {item.name}
                    </a>
                  </MenuItem>
                ))}
              </MenuList>
            </MenuContent>
          </MenuOverlay>
        ) : null
      ))}
    </>
  );
};

export default Navigation; 