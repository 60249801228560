import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import leviathanImage from '../../assets/images/deities/leviathan.png';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

// Keyframe animations for the Leviathan void effects
const voidPulse = keyframes`
  0%, 100% { box-shadow: inset 0 0 30px rgba(0, 0, 20, 0.8), 0 0 15px rgba(0, 0, 20, 0.4); }
  50% { box-shadow: inset 0 0 50px rgba(0, 0, 20, 0.95), 0 0 25px rgba(0, 0, 20, 0.6); }
`;

const dimensionalRipple = keyframes`
  0% { transform: scale(1); opacity: 0.8; filter: blur(0px); }
  50% { transform: scale(1.01); opacity: 0.6; filter: blur(0.3px); }
  100% { transform: scale(1); opacity: 0.8; filter: blur(0px); }
`;

const tendrilMovement = keyframes`
  0% { transform: rotate(0deg) scale(1); }
  33% { transform: rotate(0.5deg) scale(1.005); }
  66% { transform: rotate(-0.5deg) scale(0.995); }
  100% { transform: rotate(0deg) scale(1); }
`;

const abyssalGlow = keyframes`
  0%, 100% { filter: drop-shadow(0 0 8px rgba(20, 80, 120, 0.3)); }
  50% { filter: drop-shadow(0 0 15px rgba(60, 20, 120, 0.5)); }
`;

const consumerEffect = keyframes`
  0% { 
    clip-path: polygon(
      0% 0%, 100% 0%, 100% 100%, 0% 100%
    );
  }
  50% { 
    clip-path: polygon(
      2% 2%, 98% 1%, 99% 98%, 1% 99%
    );
  }
  100% { 
    clip-path: polygon(
      0% 0%, 100% 0%, 100% 100%, 0% 100%
    );
  }
`;

const voidTextEffect = keyframes`
  0%, 100% { text-shadow: 0 0 8px rgba(120, 140, 180, 0.2); }
  50% { text-shadow: 0 0 12px rgba(80, 100, 160, 0.4); }
`;

const abysmicShadow = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Main styled component with void theme
const StyledLeviathanPage = styled.div`
  position: relative;
  min-height: 100vh;
  color: #a0b5cc;
  background: linear-gradient(
    to bottom,
    #000510 0%,
    #01071a 40%,
    #010825 70%,
    #000510 100%
  );
  overflow-x: hidden;
  
  &::before {
    content: '';
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    background: 
      radial-gradient(circle at 30% 20%, rgba(20, 40, 80, 0.15) 0%, transparent 50%),
      radial-gradient(circle at 70% 60%, rgba(40, 10, 60, 0.1) 0%, transparent 50%);
    z-index: -1;
  }

  h1, h2, h3, h4 {
    color: #8aa8d8;
    position: relative;
    text-shadow: 
      0px 0px 5px rgba(20, 40, 80, 0.5),
      0px 0px 10px rgba(10, 20, 40, 0.3);
  }

  h1 {
    font-size: 4rem;
    letter-spacing: 4px;
    margin-bottom: 2rem;
    background: linear-gradient(45deg, #3a5a8d, #1c2c44, #536d92, #1c2c44, #3a5a8d);
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    &.void-text {
      color: #6a8bbc;
      text-shadow: 0 0 10px rgba(20, 40, 80, 0.5);
    }
  }

  h3 {
    font-size: 1.5rem;
    margin: 1rem 0;
    color: #5d7ba9;
  }

  p {
    color: #a0b5cc;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    position: relative;
    animation: ${voidTextEffect} 8s infinite ease-in-out;
  }

  .deity-path-hero {
    position: relative;
    
    img.deity-path-background {
      transition: filter 0.5s ease;
      filter: brightness(0.7) contrast(1.2) saturate(0.8) drop-shadow(0 0 20px rgba(20, 40, 80, 0.3));
      
      &:hover {
        filter: brightness(0.8) contrast(1.3) saturate(0.9) drop-shadow(0 0 30px rgba(20, 40, 80, 0.5));
      }
    }
  }

  // Void-consumed content blocks
  .content-block, .trait {
    margin: 2rem 0;
    padding: 2rem;
    position: relative;
    backdrop-filter: blur(5px);
    overflow: hidden;
    
    // Base void appearance
    background: 
      linear-gradient(135deg, rgba(10, 15, 30, 0.75), rgba(5, 10, 20, 0.85)),
      linear-gradient(to right, rgba(15, 25, 45, 0.2), rgba(10, 20, 40, 0.3));
    box-shadow: 
      0 4px 30px rgba(0, 0, 0, 0.5),
      inset 0 0 15px rgba(20, 40, 80, 0.15);
    
    // Void border effect
    border: 1px solid rgba(40, 70, 120, 0.18);
    border-right: 1px solid rgba(20, 40, 80, 0.08);
    border-bottom: 1px solid rgba(20, 40, 80, 0.08);
    
    // Slight inward curve for consuming effect
    border-radius: 2px;
    
    transition: all 0.4s ease, clip-path 0.4s ease;
    animation: ${voidPulse} 10s infinite ease-in-out;
    will-change: transform, clip-path, box-shadow;
    
    // Dynamic void consumption effect on hover/active states
    &:hover {
      box-shadow: 
        0 8px 32px rgba(0, 0, 0, 0.6),
        inset 0 0 25px rgba(20, 40, 80, 0.25);
      transform: translateY(-2px) scale(1.005);
      clip-path: polygon(
        2% 2%, 98% 1%, 99% 98%, 1% 99%
      );
      
      &::before {
        opacity: 0.7;
        animation: ${dimensionalRipple} 3s infinite ease-in-out;
      }
    }
    
    &:active {
      transform: translateY(1px) scale(0.995);
      transition: transform 0.2s ease, clip-path 0.2s ease;
      clip-path: polygon(
        3% 3%, 97% 2%, 98% 97%, 2% 98%
      );
    }
    
    // Dimensional ripple effect
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        90deg, 
        transparent, 
        rgba(20, 40, 80, 0.05), 
        rgba(20, 40, 80, 0.1), 
        rgba(20, 40, 80, 0.05), 
        transparent
      );
      background-size: 200% 100%;
      pointer-events: none;
      opacity: 0.4;
      z-index: 1;
    }
    
    // Tendril traces effect
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(135deg, transparent 85%, rgba(20, 40, 80, 0.2) 95%, transparent 100%),
        linear-gradient(45deg, transparent 85%, rgba(20, 40, 80, 0.2) 95%, transparent 100%),
        linear-gradient(-45deg, transparent 85%, rgba(20, 40, 80, 0.2) 95%, transparent 100%),
        linear-gradient(-135deg, transparent 85%, rgba(20, 40, 80, 0.2) 95%, transparent 100%);
      background-size: 150px 150px, 170px 170px, 130px 130px, 160px 160px;
      background-position: -50px -50px, 30px 30px, -20px 20px, 40px -40px;
      pointer-events: none;
      opacity: 0.3;
      z-index: 2;
      animation: ${tendrilMovement} 15s infinite ease-in-out;
    }
  }

  // Specialized styling for different block types
  .dark-block {
    background: 
      linear-gradient(135deg, rgba(5, 10, 20, 0.85), rgba(2, 5, 15, 0.95)),
      linear-gradient(to right, rgba(10, 20, 35, 0.3), rgba(5, 15, 30, 0.3));
    box-shadow: 
      0 4px 30px rgba(0, 0, 0, 0.6),
      inset 0 0 15px rgba(20, 40, 80, 0.1);
    
    &::after {
      opacity: 0.2;
    }
  }

  // Tendril decoration
  .tendril-decoration {
    position: absolute;
    background: linear-gradient(135deg, rgba(10, 20, 40, 0.2), rgba(5, 10, 20, 0.3));
    border: 1px solid rgba(20, 40, 80, 0.18);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    pointer-events: none;
    z-index: -1;
    animation: ${tendrilMovement} 15s infinite ease-in-out;
    filter: blur(1px);
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;
    overflow: visible;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #3a5a8d, #1c2c44);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 0 0 10px rgba(20, 40, 80, 0.5);
    }
  }

  .veiled-paths h2 {
    color: #6a8bbc;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: #8aa8d8;
      text-shadow: 0 0 10px rgba(20, 40, 80, 0.5);
    }
  }

  .veiled-link {
    color: #a0b5cc;
    text-decoration: none;
    transition: all 0.3s ease;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    position: relative;
    margin: 0.3rem 0;
    display: block;

    &:hover {
      color: #6a8bbc;
      text-shadow: 0 0 8px rgba(20, 40, 80, 0.4);
      padding-left: 5px;
    }

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 1px;
      background: #3a5a8d;
      transition: width 0.3s ease;
    }

    &:hover::before {
      width: 8px;
    }
  }

  // Void-themed dimensional rift effect
  .void-rift {
    clip-path: inherit;
    
    &::before {
      opacity: 0;
    }
  }
  
  .content-block:hover .void-rift::before,
  .trait:hover .void-rift::before {
    opacity: 0.2;
    animation: dimensionalRipple 3s infinite ease-in-out;
  }

  // Modal styling with void effect
  .image-modal {
    background: rgba(0, 5, 15, 0.85);
    backdrop-filter: blur(10px);

    .modal-image {
      box-shadow: 0 0 30px rgba(20, 40, 80, 0.3);
    }

    .modal-close,
    .modal-download {
      background: rgba(0, 0, 0, 0.6);
      border: 1px solid rgba(20, 40, 80, 0.3);
      color: #6a8bbc;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(20, 40, 80, 0.2);
        border-color: #3a5a8d;
      }
    }
  }

  // Interaction effects
  .content-block, .trait {
    cursor: pointer;
  }
`;

// Tendril component
const Tendril = styled.div`
  position: absolute;
  background: linear-gradient(135deg, rgba(10, 20, 40, 0.2), rgba(5, 10, 20, 0.3));
  border: 1px solid rgba(20, 40, 80, 0.18);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4), inset 0 0 5px rgba(20, 40, 80, 0.2);
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
  filter: blur(1px);
  animation: ${tendrilMovement} 15s infinite ease-in-out;
  pointer-events: none;
  z-index: -1;
  will-change: transform;
`;

// Void Rift component for adding to content blocks
const VoidRift = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  pointer-events: none;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: radial-gradient(
      circle at center,
      rgba(20, 40, 80, 0.1),
      rgba(10, 20, 40, 0.1),
      rgba(5, 10, 20, 0.05)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: inherit;
    will-change: opacity;
  }
`;

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 }
};

const contentVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.7, ease: "easeOut" }
};

const PantheonLeviathan = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const pageRef = useRef(null);

  // Track mouse movement for parallax effects - optimize for performance
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (pageRef.current) {
        // Use requestAnimationFrame for smoother updates
        requestAnimationFrame(() => {
          const { width, height } = pageRef.current.getBoundingClientRect();
          const x = (e.clientX / width) - 0.5;
          const y = (e.clientY / height) - 0.5;
          setMousePosition({ x, y });
        });
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <StyledLeviathanPage ref={pageRef}>
      {/* Tendril decorations with parallax effect - optimize transitions */}
      <Tendril 
        style={{ 
          top: '15%', 
          right: '5%', 
          width: '170px', 
          height: '170px', 
          opacity: 0.3, 
          animationDelay: '0s',
          transform: `translate(${mousePosition.x * -20}px, ${mousePosition.y * -20}px) rotate(${mousePosition.x * 5}deg)`,
          transition: 'transform 0.3s ease-out'
        }} 
      />
      <Tendril 
        style={{ 
          top: '35%', 
          left: '3%', 
          width: '120px', 
          height: '120px', 
          opacity: 0.25, 
          animationDelay: '1s',
          transform: `translate(${mousePosition.x * 15}px, ${mousePosition.y * 15}px) rotate(${mousePosition.y * -3}deg)`,
          transition: 'transform 0.3s ease-out'
        }} 
      />
      <Tendril 
        style={{ 
          bottom: '15%', 
          right: '8%', 
          width: '150px', 
          height: '150px', 
          opacity: 0.3, 
          animationDelay: '2s',
          transform: `translate(${mousePosition.x * -25}px, ${mousePosition.y * 10}px) rotate(${mousePosition.x * -2}deg)`,
          transition: 'transform 0.3s ease-out'
        }} 
      />
      <Tendril 
        style={{ 
          bottom: '30%', 
          left: '7%', 
          width: '200px', 
          height: '200px', 
          opacity: 0.2, 
          animationDelay: '3s',
          transform: `translate(${mousePosition.x * 30}px, ${mousePosition.y * -15}px) rotate(${mousePosition.y * 4}deg)`,
          transition: 'transform 0.3s ease-out'
        }} 
      />
      
      {/* Additional tendrils that follow mouse movement more closely */}
      <Tendril 
        style={{ 
          top: '50%', 
          left: '20%', 
          width: '60px', 
          height: '60px', 
          opacity: 0.15, 
          animationDelay: '0.5s',
          transform: `translate(${mousePosition.x * 40}px, ${mousePosition.y * 40}px) rotate(${mousePosition.x * 10}deg)`,
          transition: 'transform 0.2s ease-out'
        }} 
      />
      <Tendril 
        style={{ 
          top: '25%', 
          right: '15%', 
          width: '50px', 
          height: '50px', 
          opacity: 0.1, 
          animationDelay: '1.5s',
          transform: `translate(${mousePosition.x * -35}px, ${mousePosition.y * 45}px) rotate(${mousePosition.y * -8}deg)`,
          transition: 'transform 0.2s ease-out'
        }} 
      />
    
      <motion.div 
        className="deity-path-page"
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.div 
          className="deity-path-hero"
          variants={contentVariants}
        >
          <img
            src={leviathanImage}
            alt="Leviathan: The Devourer of Worlds"
            className="deity-path-background"
            onClick={() => setShowFullImage(true)}
            style={{ cursor: 'pointer' }}
          />
          <div className="deity-path-title">
            <h1>Leviathan: The Devourer of Worlds</h1>
          </div>
        </motion.div>

        <motion.div 
          className="content-block dark-block"
          variants={contentVariants}
        >
          <VoidRift className="void-rift" />
          <h2 className="void-text">Lore</h2>
          <p>
            The Leviathan stands as a cosmic anomaly of unfathomable scale and inscrutable power, transcending the boundaries of divinity and natural law. It embodies an unrelenting force of annihilation, consuming without purpose beyond its intrinsic hunger. Vast tendrils of shadow spread from its amorphous form like roots of a great tree, grasping and consuming all they touch, leaving behind only voids of silence and oblivion.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <VoidRift className="void-rift" />
          <h2>The Leviathan's Influence</h2>
          <p>
            The Leviathan's presence is a chilling constant across the multiverse, an ever-present harbinger of dread that neither seeks nor demands worship. Instead, it exerts a quiet, pervasive terror that touches even the divine. To confront the Leviathan is to glimpse the precarious nature of existence itself.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <VoidRift className="void-rift" />
          <div className="trait-header">
            <h3>Harbinger of Ruin</h3>
          </div>
          <p>
            The Leviathan's arrival is foretold by dimensional disruptions and waves of lifeless cold that ripple through the cosmos. Such signs are interpreted as curses or portents of doom by those who encounter them.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <VoidRift className="void-rift" />
          <div className="trait-header">
            <h3>Cosmic Incubation</h3>
          </div>
          <p>
            Its consumption is likened to a seed taking root in the remnants of annihilated worlds. Each devoured world fuels its relentless growth, driving it toward an unknowable apex.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <VoidRift className="void-rift" />
          <div className="trait-header">
            <h3>Physical Manifestation</h3>
          </div>
          <p>
            The Leviathan's form defies mortal comprehension, appearing as an amorphous vortex of darkness that absorbs light, matter, and understanding itself. Its presence is a visceral unraveling of reality, challenging even the staunchest perceptions of order.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <VoidRift className="void-rift" />
          <div className="trait-header">
            <h3>Abyssal Bloom</h3>
          </div>
          <p>
            Its arrival is heralded by a rift in the heavens, a void that unfurls tendrils of darkness like roots spreading across a shattered land.
          </p>
        </motion.div>

        <motion.div 
          className="trait"
          variants={contentVariants}
        >
          <VoidRift className="void-rift" />
          <div className="trait-header">
            <h3>Legion of Scytherai</h3>
          </div>
          <p>
            The Leviathan's vanguard, the Scytherai, are insectoid harbingers that descend in an unstoppable tide, their claws and wings echoing the dirge of worlds.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <VoidRift className="void-rift" />
          <h2>Legends and Mysteries</h2>
          <p>
            Scholars and mystics alike whisper theories about the Leviathan's origin. Some argue it is an echo of the void that predates creation; others suggest it is the remnant of a primordial cataclysm. Regardless of its source, it exists as a testament to forces older and more profound than the gods themselves.
          </p>
        </motion.div>

        <motion.div 
          className="content-block"
          variants={contentVariants}
        >
          <VoidRift className="void-rift" />
          <h2>Divine Aspects</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Alternate Names</h3>
            </div>
            <p>The Devourer of Worlds, The Abyssal Bloom, The Eternal Maw</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Domains</h3>
            </div>
            <p>Annihilation, Void</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Pantheon</h3>
            </div>
            <p>None (Primordial force; predates pantheons)</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Symbol</h3>
            </div>
            <p>A gaping, black maw surrounded by tendrils of light devouring a galaxy</p>
          </div>
        </motion.div>

        {/* Image Modal */}
        {showFullImage && (
          <motion.div 
            className="image-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowFullImage(false)}
          >
            <motion.div 
              className="modal-image-container"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: "spring", stiffness: 200, damping: 20 }}
            >
              <img 
                src={leviathanImage} 
                alt="Leviathan: The Devourer of Worlds"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={leviathanImage}
                download="Leviathan.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </StyledLeviathanPage>
  );
};

export default PantheonLeviathan; 