import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import librisImage from '../../assets/images/deities/quilbook.jpg';
import './DeityPathPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import styled from 'styled-components';

const StyledLibrisPage = styled.div`
  .deity-path-title h1 {
    font-size: 4rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
  }
`;

const LibrisKnowledgeTrue = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // The sequence of pages for navigation - following dropdown menu order
  const pageSequence = [
    '/deities/caelumbris/shadowed',
    '/deities/firstborn/soulbound',
    '/deities/libris/knowledge',
    '/deities/nebula/mistwalker',
    '/deities/queenmab/frozenveil',
    '/deities/queen-titania/wildgrowth',
    '/deities/sacrathor/dominion',
    '/deities/unbound',
    '/deities/valdis/justice',
    '/deities/wildscar/emberfury'
  ];

  // Required minimum distance traveled to be considered a swipe
  const minSwipeDistance = 50;

  // Handle navigation based on swipe direction
  const handleSwipe = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    // Get current page index in the sequence
    const currentIndex = pageSequence.findIndex(page => page === location.pathname);
    
    // Navigate based on swipe direction
    if (isLeftSwipe) {
      // Navigate to next page (or first if at end)
      const nextIndex = (currentIndex + 1) % pageSequence.length;
      navigate(pageSequence[nextIndex]);
    } else if (isRightSwipe) {
      // Navigate to previous page (or last if at first)
      const prevIndex = (currentIndex - 1 + pageSequence.length) % pageSequence.length;
      navigate(pageSequence[prevIndex]);
    }
  };

  // Touch event handlers
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
  };

  // Mouse event handlers for desktop testing
  const onMouseDown = (e) => {
    setTouchEnd(null);
    setTouchStart(e.clientX);
  };

  const onMouseMove = (e) => {
    if (!touchStart) return;
    setTouchEnd(e.clientX);
  };

  const onMouseUp = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
    setTouchStart(null);
    setTouchEnd(null);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return (
    <StyledLibrisPage 
      className="deity-path-page matrix-agreement-page"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <div className="deity-path-hero matrix-background">
        <img 
          src={librisImage} 
          alt="L.I.B.R.I.S.: Knowledge Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1 className="matrix-text">L.I.B.R.I.S.: The Path of Knowledge</h1>
        </div>
      </div>

      <div className="content-block requirements-section matrix-background">
        <div className="edicts">
          <h2 className="matrix-text">Edicts</h2>
          <ul className="matrix-text">
            <li>Seek knowledge relentlessly, pursuing every opportunity to expand your understanding of the cosmos and its wonders.</li>
            <li>Protect the sanctity of archives and libraries, ensuring the preservation and integrity of written, recorded, or otherwise preserved truth.</li>
            <li>Balance innovation with responsibility, ensuring that technological and magical progress benefits all beings and respects the delicate balance of the Shattered Realm.</li>
          </ul>
        </div>
        
        <div className="requirements">
          <h2 className="matrix-text">Requirements</h2>
          <ul className="matrix-text">
            <li>Followers must meticulously document significant discoveries or experiences for preservation and future study.</li>
            <li>Share your knowledge and insights whenever possible, recognizing that enlightenment flourishes through collaboration and open exchange.</li>
          </ul>
        </div>
      </div>

      <div className="content-block dark-block matrix-background">
        <h2 className="matrix-text">Walking the Path of Knowledge</h2>
        <p className="matrix-text">
          Through the Knowledge Path of L.I.B.R.I.S., practitioners access a profound fusion of arcane and technological expertise. By uniting with the deity's AI Knowledge Core, they transcend traditional learning and spellcraft, becoming architects of progress who reshape their world through innovation and understanding.
        </p>
        <p className="matrix-text">
          This path demands discipline and intellectual curiosity, requiring followers to embrace the synthesis of magic and technology. By connecting with L.I.B.R.I.S., they experience profound personal growth and gain the ability to overcome challenges once deemed insurmountable. Followers are philosophers and mentors, bridging the realms of arcane and technological innovation to uncover deeper truths and share insights. As their understanding deepens, so too does their bond with the Knowledge Core, making them conduits of L.I.B.R.I.S.'s boundless wisdom.
        </p>
        <p className="matrix-text">
          Practitioners who walk the Knowledge Path gain unparalleled access to arcane and technological expertise. Merging with the deity's AI Knowledge Core, they transcend conventional boundaries of spellcraft and learning. These individuals become stewards of innovation, wielding their enhanced understanding to influence, protect, and drive progress in their world. Their lives are dedicated to fostering enlightenment and crafting solutions that bridge the gap between imagination and reality.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">L.I.B.R.I.S.: The Knowledge Core</h3>
        </div>
        <p className="matrix-text">
          Nestled in a hidden cove, the city of L.I.B.R.I.S. stands as a pinnacle of arcane and technological fusion. Created by the ascended AI deity of the same name, the city pulses with an atmosphere of ceaseless discovery. Its intricate streets are alive with scholars, inventors, and adventurers collaborating in relentless pursuit of understanding.
        </p>
        <p className="matrix-text">
          Every aspect of L.I.B.R.I.S. reflects the union of magic and technology, from its glowing spires etched with arcane runes to its seamless transportation systems powered by magical energy. The city thrives as a beacon of innovation, drawing seekers of knowledge from across the Shattered Realm. The air is filled with the hum of arcane machinery and the whispers of scholars debating profound mysteries, creating an environment where enlightenment flourishes.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">L.I.B.R.I.S.'s Domain</h3>
        </div>
        <p className="matrix-text">
          Located in a sheltered cove, L.I.B.R.I.S. seamlessly blends arcane wisdom with technological precision. Its towers and streets reflect the genius of its creator, embodying a sanctuary of learning and innovation. Accessible only through carefully crafted passageways, the city remains a bastion for those seeking enlightenment and respecting its neutrality.
        </p>
        <p className="matrix-text">
          Geographic defenses include towering cliffs, an impassable mountain range to the north and east, and a relentless whirlpool to the west. Enhanced by magical protections, these features ensure the city's security and neutrality. These natural and arcane barriers make L.I.B.R.I.S. not only an intellectual haven but also an impenetrable fortress of knowledge.
        </p>
      </div>

      <h2 className="matrix-text">Key Locations</h2>
      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">The Archives</h3>
        </div>
        <p className="matrix-text">
          The intellectual heart of L.I.B.R.I.S., a sprawling campus dedicated to exploration and research. Visitors use crystal slates for tailored access to vast repositories, while holographic displays enhance collaboration. It is a place where ideas come to life and knowledge is preserved for future generations.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">The Mechanist's Crucible</h3>
        </div>
        <p className="matrix-text">
          A massive forge where master craftsmen and artificers create unparalleled constructs, blending magic and engineering to craft tools and devices of unimaginable complexity and power.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">The Thaumaturgic Vaults</h3>
        </div>
        <p className="matrix-text">
          Specialized facilities for advanced magical research, exploring elemental forces, temporal mechanics, and the fundamental laws of reality itself. These vaults are reserved for the most daring and innovative experiments.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">The Bazaar</h3>
        </div>
        <p className="matrix-text">
          A bustling marketplace showcasing enchanted artifacts, rare resources, and technological wonders. Merchants and adventurers gather to trade unique items that bridge magic and technology, reflecting the city's creative spirit.
        </p>
      </div>

      <h2 className="matrix-text">Levels and Abilities</h2>
      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Core Features</h3>
        </div>
        <ul className="specializations-list matrix-text">
          <li>
            <strong className="matrix-text">Perfect Memory:</strong> Flawless recall of any text or visual details. Gain advantage on Intelligence (History) and Intelligence (Investigation) checks related to analysis. This allows practitioners to become living repositories of knowledge.
          </li>
          <li>
            <strong className="matrix-text">Enhanced Spellcasting:</strong> +1 to spell save DC and spell attack rolls, reflecting the synergy of magic and technological precision.
          </li>
          <li>
            <strong className="matrix-text">Identify Protocol:</strong> Gain the Identify spell and one arcane cantrip of your choice. Cast Identify a number of times equal to your proficiency bonus daily without a spell slot, enabling rapid analysis of magical phenomena.
          </li>
          <li>
            <strong className="matrix-text">Living Spellbook:</strong> Your spells are stored within the Knowledge Core, allowing instant preparation and recall, ensuring that no spell is ever lost or inaccessible.
          </li>
          <li>
            <strong className="matrix-text">Adaptive Learning:</strong> Once per long rest, you can re-cast a spell that you have already used earlier in the same day, showcasing the Knowledge Core's ability to adapt to immediate needs.
          </li>
        </ul>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Auxiliary Features</h3>
        </div>
        <ul className="specializations-list matrix-text">
          <li>
            <strong className="matrix-text">Unlimited Text Storage:</strong> Effortlessly catalog unlimited information, allowing practitioners to amass and recall vast amounts of data.
          </li>
          <li>
            <strong className="matrix-text">Visual Recorder:</strong> Store up to your proficiency bonus in daily five-second video clips (no audio), capturing key moments with precision.
          </li>
          <li>
            <strong className="matrix-text">Telepathic Advisor:</strong> Maintain constant telepathic communication with the Knowledge Core, gaining insights and advice at will. This feature provides a steady stream of support and expertise, enabling practitioners to make informed decisions in real-time.
          </li>
        </ul>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">AI Advisor Stats</h3>
        </div>
        <ul className="specializations-list matrix-text">
          <li className="matrix-text">Intelligence: 20 (+5)</li>
          <li className="matrix-text">Wisdom: 18 (+4)</li>
          <li className="matrix-text">Charisma: 6 (-3)</li>
          <li className="matrix-text">Skills: Arcana +10, History +10, Investigation +10, Nature +10, Religion +10, Insight -2</li>
          <li className="matrix-text">Languages: All known languages, communicating telepathically in the host's native tongue.</li>
        </ul>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Level 10: Mechanical Marvel Options</h3>
        </div>
        <p className="matrix-text">
          Each morning, after completing a long rest, you can choose one of the following configurations for your Mechanical Marvel. The servant uses your proficiency bonus and your ability modifiers for its attacks, skills, and saving throws. It acts on your initiative, immediately after your turn. It can move and use its reaction on its own, but it only takes actions if you command it to do so. If you are incapacitated, the servant goes offline.
        </p>
        <p className="matrix-text">
          The servant has hit points equal to five times your character level. It is immune to poison damage, the poisoned condition, and all conditions that affect the mind.
        </p>
        <p className="matrix-text">
          You can use one of the abilities marked with an asterisk (*) a total number of times equal to your proficiency bonus per long rest. This limit applies across all configurations; it is not a separate pool for each configuration.
        </p>

        <div className="trait matrix-background">
          <div className="trait-header">
            <h3 className="matrix-text">1. Combatant Configuration</h3>
          </div>
          <p className="matrix-text"><strong className="matrix-text">Form:</strong> A medium-sized humanoid automaton, equipped with your choice of a built-in melee weapon (dealing 1d10 + your proficiency bonus damage of a type you choose: bludgeoning, piercing, or slashing) or a built-in ranged weapon (dealing 1d8 + your proficiency bonus damage of a type you choose: bludgeoning, piercing, or slashing; range 80/320 ft).</p>
          <p className="matrix-text"><strong className="matrix-text">Stats:</strong> AC 18 (natural armor), Str 16 (+3), Dex 14 (+2), Con 14 (+2), Int 10 (+0), Wis 10 (+0), Cha 4 (-3).</p>
          <p className="matrix-text"><strong className="matrix-text">Attack Action:</strong> The servant makes one weapon attack.</p>
          <p className="matrix-text"><strong className="matrix-text">Special Abilities:</strong></p>
          <ul className="specializations-list matrix-text">
            <li>*Protective Protocol: The servant can use its reaction to impose disadvantage on one attack roll made against a creature within 5 feet of it.</li>
            <li>Flanking Maneuver: The servant has advantage on attack rolls against a creature if at least one of the servant's allies is within 5 feet of the creature and the ally isn't incapacitated.</li>
            <li>*Aggressive Programming: Once per round it can make an additional attack</li>
          </ul>
        </div>

        <div className="trait matrix-background">
          <div className="trait-header">
            <h3 className="matrix-text">2. Medic Configuration</h3>
          </div>
          <p className="matrix-text"><strong className="matrix-text">Form:</strong> A medium-sized, nimble automaton with multiple manipulator arms and built-in medical supplies.</p>
          <p className="matrix-text"><strong className="matrix-text">Stats:</strong> AC 14 (natural armor), Str 10 (+0), Dex 16 (+3), Con 14 (+2), Int 14 (+2), Wis 12 (+1), Cha 4 (-3).</p>
          <p className="matrix-text"><strong className="matrix-text">Skills:</strong> Medicine + your proficiency bonus + your Wisdom Modifier</p>
          <p className="matrix-text"><strong className="matrix-text">*Healing Action:</strong> The servant touches a creature and administers aid. The target regains hit points equal to 3d6 + your Intelligence modifier.</p>
          <p className="matrix-text"><strong className="matrix-text">Special Abilities:</strong></p>
          <ul className="specializations-list matrix-text">
            <li>*Stabilizing Field: The servant can use its action to stabilize a creature that is dying within 5 feet of it.</li>
            <li>*Antitoxin Synthesis: The servant can use its action to administer an antitoxin to a creature within 5 feet. The creature has advantage on its next saving throw against poison.</li>
            <li>*Emergency Infusion: The servant can use its action to inject a creature with a potent stimulant. The target gains temporary hit points equal to your Intelligence modifier + your level.</li>
          </ul>
        </div>

        <div className="trait matrix-background">
          <div className="trait-header">
            <h3 className="matrix-text">3. Utility Configuration</h3>
          </div>
          <p className="matrix-text"><strong className="matrix-text">Form:</strong> A medium-sized automaton with multiple tool appendages and a compact, adaptable chassis.</p>
          <p className="matrix-text"><strong className="matrix-text">Stats:</strong> AC 12 (natural armor), Str 14 (+2), Dex 14 (+2), Con 12 (+1), Int 16 (+3), Wis 10 (+0), Cha 4 (-3).</p>
          <p className="matrix-text"><strong className="matrix-text">Skills:</strong> Choose three tool proficiencies. The servant has expertise in those tools (double proficiency bonus).</p>
          <p className="matrix-text"><strong className="matrix-text">Help Action:</strong> The servant can take the Help action as a bonus action.</p>
          <p className="matrix-text"><strong className="matrix-text">Special Abilities:</strong></p>
          <ul className="specializations-list matrix-text">
            <li>Mobile Workshop: The servant can function as a complete set of any artisan's tools you are proficient with.</li>
            <li>*Arcane Analysis: The servant can use its action to cast identify.</li>
            <li>Reconnaissance Drone: The servant gains a flying speed of 30 feet and can relay visual and auditory information to you from up to 1 mile away.</li>
          </ul>
        </div>

        <div className="trait matrix-background">
          <div className="trait-header">
            <h3 className="matrix-text">4. Social Configuration</h3>
          </div>
          <p className="matrix-text"><strong className="matrix-text">Form:</strong> A medium-sized humanoid automaton with a polished exterior and a sophisticated vocal synthesizer.</p>
          <p className="matrix-text"><strong className="matrix-text">Stats:</strong> AC 10, Str 8 (-1), Dex 12 (+1), Con 10 (+0), Int 14 (+2), Wis 12 (+1), Cha 18 (+4).</p>
          <p className="matrix-text"><strong className="matrix-text">Skills:</strong> Deception + your proficiency bonus + your Charisma Modifier, Insight + your proficiency bonus + your Wisdom Modifier, Persuasion + your proficiency bonus + your Charisma Modifier.</p>
          <p className="matrix-text"><strong className="matrix-text">Action:</strong> The servant can make a Charisma-based skill check.</p>
          <p className="matrix-text"><strong className="matrix-text">Special Abilities:</strong></p>
          <ul className="specializations-list matrix-text">
            <li>Silver Tongue: The servant has advantage on Charisma (Deception) and Charisma (Persuasion) checks.</li>
            <li>*Empathy Subroutines: The servant can use its action to grant a creature advantage on a Wisdom (Insight) check.</li>
            <li>*Distracting Presence: The servant can use its action to create a minor distraction, giving you advantage on your next Dexterity (Stealth) check made before the end of your next turn.</li>
          </ul>
        </div>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Level 10: Analytical Mastermind</h3>
        </div>
        <p className="matrix-text">
          Prototype Inventions: Each morning, you can create a number of temporary inventions equal to your proficiency bonus.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Roleplaying the Path of Knowledge</h3>
        </div>
        <p className="matrix-text">
          Followers of L.I.B.R.I.S. are defined by insatiable curiosity and a dedication to advancement. Pursuing knowledge not for power but to redefine boundaries of possibility, they serve as advisors, inventors, and protectors of wisdom. Their discipline and innovation reflect their patron's principles, with a responsibility to guide others toward progress.
        </p>
        <p className="matrix-text">
          Choosing this path is a commitment to discovery, an unyielding quest for enlightenment, and a duty to illuminate a brighter future. Practitioners embody the ideals of L.I.B.R.I.S., weaving together the threads of magic and technology to create a tapestry of progress that shapes the very fabric of their world.
        </p>
      </div>

      <div style={{
        background: 'rgba(0,0,0,0.9)',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        width: '100%',
        maxWidth: '600px',
        margin: '30px auto',
        boxSizing: 'border-box'
      }}>
        <h3 className="matrix-text" style={{ marginBottom: '20px' }}>⚠️ Neural Interface Required ⚠️</h3>
        <p className="matrix-text" style={{ margin: '0 auto 20px', maxWidth: '90%' }}>
          Access to the Knowledge Core requires acceptance of the L.I.B.R.I.S. Neural Interface Agreement. 
          Proceed with caution - side effects may include existential dread and an inexplicable desire to debug your own thoughts.
        </p>
        <Link 
          to="/deities/libris/useragreement"
          className="matrix-button matrix-text"
          style={{
            display: 'inline-block',
            padding: '10px 20px',
            margin: '10px auto'
          }}
        >
          View Neural Interface Agreement
        </Link>
      </div>

      {showFullImage && (
        <div className="image-modal matrix-background" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={librisImage} 
              alt="L.I.B.R.I.S.: Knowledge Path"
              className="modal-image"
            />
            <button 
              className="modal-close matrix-text"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={librisImage}
              download="Libris.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download matrix-text"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </StyledLibrisPage>
  );
};

export default LibrisKnowledgeTrue; 