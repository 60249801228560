import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Lifedrinker/lifedrinker.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesLifedrinker = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Lifedrinker" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Lifedrinker</h1>
          
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Lore</h2>
          <p>
            Lifedrinkers are the result of a rare and dangerous fusion of Ki manipulation and vampiric essence. Their creation traces back to the Protiengier, a wandering monk whose mastery over Ki was unparalleled. According to legend, the Protiengier encountered a parasitic creature that fed on his life force. Desperate to survive, he turned his mastery inward, fusing his Ki with the predator's essence. The fusion transformed them both into something unnatural. When the process ended, the monk was gone, leaving behind a single new being: the first Lifedrinker.
          </p>
        </div>

        <div className="content-block">
          <p>
            Lifedrinkers walk the world as enigmatic wanderers, balancing their mastery of Ki with an insatiable hunger for life energy. Their presence is haunting yet magnetic, their striking beauty masking the predator within. Lifedrinkers have pale, marble-like skin and sharp, elegant features. Their eyes glimmer faintly in dim light, hinting at the supernatural power that lies beneath.
          </p>
        </div>

        <div className="content-block">
          <h2>Society</h2>
          <p>
            The Lifedrinkers' society is akin to the intricate and hierarchical structure of an Imperial society, with an omnipotent emperor at its apex. This emperor, a figure of both reverence and fear, governs with an iron fist, ensuring that the Lifedrinkers' dominion remains unchallenged. Only Lifedrinkers are granted the status of citizens, while all other races are relegated to the role of livestock, existing solely to serve the needs and desires of their masters.
          </p>
        </div>

        <div className="content-block">
          <p>
            After the cataclysmic event known as the Shattering, the area that now serves as the Lifedrinkers' capital was transformed into a teeming bastion of primal life and savagery. The Lifedrinkers, with their unparalleled prowess and ruthless efficiency, swiftly eliminated the myriad threats that lurked within this wilderness. They asserted their dominance over the inhabitants, offering them a life devoid of want or need in exchange for their complete subjugation. Poverty, hunger, and crime became distant memories, replaced by a rigid order that ensured the livestock's survival, albeit at the cost of their freedom.
          </p>
        </div>

        <div className="content-block">
          <p>
            Non-Lifedrinkers who venture into their territory are stripped of all rights, reduced to the status of livestock. However, through the patronage of a Lifedrinker citizen, these individuals can obtain special permissions, such as the right to engage in trade or other mercantile activities.
          </p>
        </div>

        <div className="content-block">
          <p>
            The lands of the Lifedrinkers remain a primordial wilderness, teeming with elementals, blight creatures, and other horrors that constantly emerge from the depths of the world beneath Velum. Yet, the livestock are rarely troubled by these threats, as the Lifedrinkers take their role as protectors with utmost seriousness. Over the years, this unwavering protection has cultivated a fanatical trust among the livestock, who view their masters with a mixture of awe and fear.
          </p>
        </div>

        <div className="content-block">
          <p>
            Lifedrinkers are permitted to feed on any unclaimed livestock within their territory, and they may claim ownership of any livestock not already owned by another Lifedrinker.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Creature Type</h3>
            </div>
            <p>Humanoid</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium or Small (your choice)</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase two ability scores of your choice by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Darkvision</h3>
            </div>
            <p>You can see in dim light within 60 feet as if it were bright light and in darkness as if it were dim light. You cannot discern color in darkness, only shades of gray.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Deathless Nature</h3>
            </div>
            <p>You do not need to breathe.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Spider Climb</h3>
            </div>
            <p>You have a climbing speed equal to your walking speed. Starting at 3rd level, you can move across vertical surfaces and ceilings without using your hands.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ki Sensitivity</h3>
            </div>
            <p>You can sense the Ki of creatures within 30 feet of you. You are aware of their emotional state and whether they are injured, frightened, or charmed. Starting at 5th level, you can sense invisible or hidden creatures within 10 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Proficient Predator</h3>
            </div>
            <p>You gain proficiency in one of the following skills: Athletics, Acrobatics, Insight, or Perception. This reflects the heightened instincts of a hunter attuned to their surroundings and prey.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ki Feeding Technique</h3>
            </div>
            <p>As an action, you can siphon life force from a creature within 5 feet of you. The target takes 1d4 necrotic damage, and you heal for the same amount. At 5th level, this damage increases to 2d4, at 10th level to 3d4, and at 15th level, you can use this ability on creatures within 15 feet of you.</p>
            <p>When you feed, your predatory nature is unleashed: your eyes blaze a deep red, black veins writhe visibly under your skin, and dark, red-black vapor rises from your body. This display lasts until the end of your next turn.</p>
            <p>You can use this ability a number of times equal to your proficiency bonus (rounded down, minimum of 1), regaining all uses after a long rest.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Hungering Empowerment</h3>
            </div>
            <p>Each time you use Ki Feeding Technique, you enter a heightened state of power that lasts for 1 hour. During this time, your body undergoes a dramatic transformation, reflecting the energy you've absorbed. Your eyes burn a vivid red, black veins writhe beneath your skin, and a red-black vapor surrounds you.</p>
            <p>While under the effects of Hungering Empowerment, you gain the following benefits:</p>
            <p>Your walking speed is doubled</p>
            <p>You gain advantage on all Strength (Athletics), Dexterity (Acrobatics), and Constitution saving throws.</p>
            <p>You gain a bonus to all attack rolls and damage rolls with melee or unarmed strikes equal to half your proficiency bonus (rounded down, minimum of 1).</p>
            <p>These benefits refresh after each successful use of Ki Feeding Technique, allowing you to sustain the empowered state as long as you continue to feed.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Overindulgence</h3>
            </div>
            <p>If you feed beyond your allowed uses of Ki Feeding Technique, you experience escalating backlashes after Hungering Empowerment ends. These effects occur automatically and cannot be avoided:</p>
            <p><strong>Stage 1:</strong> After the first extra feeding, you gain one level of exhaustion.</p>
            <p><strong>Stage 2:</strong> After the second extra feeding, you gain two levels of exhaustion and are poisoned for 24 hours.</p>
            <p><strong>Stage 3:</strong> After the third extra feeding, you lose control for 1 hour. During this time, you are compelled to feed on any living creature nearby, doubling all bonuses from Hungering Empowerment. During the period of uncontrolled feeding, you ignore the effects of exhaustion and poisoning. Once the hour ends, all exhaustion and poison effects return, and you gain two additional levels of exhaustion.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common and one other language of your choice.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h2>Roleplaying Considerations</h2>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Mysterious:</strong> You have an enigmatic aura that makes others curious about your true nature and intentions.</p>
            <p><strong>Predatory:</strong> You have a natural instinct for hunting and survival, always aware of your surroundings and potential threats.</p>
            <p><strong>Disciplined:</strong> Your mastery of Ki requires a high level of self-control and focus, which you apply to all aspects of your life.</p>
            <p><strong>Haunted:</strong> The fusion of Ki and vampiric essence leaves you with a constant internal struggle, affecting your mood and behavior.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Balance:</strong> You strive to maintain a balance between your predatory nature and your disciplined control over Ki.</p>
            <p><strong>Survival:</strong> You are driven by the need to survive and thrive, using your unique abilities to ensure your continued existence.</p>
            <p><strong>Mastery:</strong> You seek to perfect your control over Ki and your predatory instincts, aiming for ultimate mastery.</p>
            <p><strong>Harmony:</strong> You believe in living in harmony with nature and the world around you, using your powers responsibly.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>Mentor:</strong> You have a deep respect and loyalty towards the one who guided you in mastering your Ki and understanding your nature.</p>
            <p><strong>Community:</strong> You feel a strong connection to other Lifedrinkers, sharing a unique bond and understanding.</p>
            <p><strong>Mission:</strong> You are driven by a specific goal or quest, whether it be to understand your origins or to protect others from the dangers you face.</p>
            <p><strong>Nature:</strong> You have a deep appreciation for the natural world and the life energy that sustains it.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Impulsive:</strong> Your predatory instincts can sometimes override your discipline, leading you to act impulsively.</p>
            <p><strong>Isolated:</strong> Your unique nature sets you apart from others, making it difficult for you to form meaningful connections.</p>
            <p><strong>Obsessive:</strong> Your drive for mastery and survival can become all-consuming, leading you to neglect other important aspects of your life.</p>
            <p><strong>Reckless:</strong> Your hunger for life energy can sometimes lead you to take unnecessary risks, endangering yourself and others.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Lifedrinker"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Lifedrinker.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesLifedrinker; 