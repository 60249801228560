import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LoginPage from './login-page/LoginPage';
import { AuthProvider, useAuth } from './context/AuthContext';
import Navigation from './components/Navigation';

// Import First Veil pages
import Help from './pages/Help';
import History from './pages/History';
import HouseRules from './pages/HouseRules';
import SpellsAndAbilities from './pages/SpellsAndAbilities';

// Import Deity Components
import CaelumbrisShadowed from './pages/deities/CaelumbrisShadowed';
import LibrisKnowledge from './pages/deities/LibrisKnowledge';
import LibrisKnowledgeTrue from './pages/deities/LibrisKnowledgeTrue';
import FirstbornSoulbound from './pages/deities/FirstbornSoulbound';
import NebulaMistWalker from './pages/deities/NebulaMistWalker';
import QueenMabFrozenVeil from './pages/deities/QueenMabFrozenVeil';
import QueenTitaniaWildgrowth from './pages/deities/QueenTitaniaWildgrowth';
import SacrathorDominion from './pages/deities/SacrathorDominion';
import Unbound from './pages/deities/Unbound';
import ValdisJustice from './pages/deities/ValdisJustice';
import WildscarEmberFury from './pages/deities/WildscarEmberFury';
import LibrisAgreement from './pages/deities/useragreement';

// Import Dashboard components
import CommonerDashboard from './dashboard/CommonerDashboard';
import AdventurerDashboard from './dashboard/AdventurerDashboard';
import AdminDashboard from './dashboard/AdminDashboard';

// Import Pantheon Components
import PantheonCaelumbris from './pages/deities/PantheonCaelumbris';
import PantheonCalanthar from './pages/deities/PantheonCalanthar';
import PantheonLeviathan from './pages/deities/PantheonLeviathan';
import PantheonFirstborn from './pages/deities/PantheonFirstborn';
import PantheonLibris from './pages/deities/PantheonLibris';
import PantheonNebula from './pages/deities/PantheonNebula';
import PantheonQueenMab from './pages/deities/PantheonQueenMab';
import PantheonQueenTitania from './pages/deities/PantheonQueenTitania';
import PantheonSacrathor from './pages/deities/PantheonSacrathor';
import PantheonValdis from './pages/deities/PantheonValdis';
import PantheonWildscar from './pages/deities/PantheonWildscar';

// Import Classes
import ClassesBurningSeed from './pages/classes/ClassesBurningSeed';
import ClassesDronewright from './pages/classes/ClassesDronewright';
import ClassesGlacialHeart from './pages/classes/ClassesGlacialHeart';
import ClassesPathOfWrath from './pages/classes/ClassesPathOfWrath';
import ClassesPyromancer from './pages/classes/ClassesPyromancer';
import ClassesSeraphicGuardian from './pages/classes/ClassesSeraphicGuardian';
import ClassesSovereignZealot from './pages/classes/ClassesSovereignZealot';
import ClassesUmbralInfiltrator from './pages/classes/ClassesUmbralInfiltrator';
import ClassesWayOfTheLifedrinker from './pages/classes/ClassesWayOfTheLifedrinker';
import ClassesWinterbornKnight from './pages/classes/ClassesWinterbornKnight';

// Import Species
import SpeciesGravewalker from './pages/species/SpeciesGravewalker';
import SpeciesHumanity from './pages/species/SpeciesHumanity';
import SpeciesLifedrinker from './pages/species/SpeciesLifedrinker';
import SpeciesManaforged from './pages/species/SpeciesManaforged';
import SpeciesScytherai from './pages/species/SpeciesScytherai';
import SpeciesSeraphiend from './pages/species/SpeciesSeraphiend';
import SpeciesSkinWalker from './pages/species/SpeciesSkinWalker';
import SpeciesStarborn from './pages/species/SpeciesStarborn';
import SpeciesSunseed from './pages/species/SpeciesSunseed';
import SpeciesVermisCorpus from './pages/species/SpeciesVermisCorpus';
import SpeciesWinterWitch from './pages/species/SpeciesWinterWitch';

import './App.css';

// Create a simple Dashboard component to replace the deleted one
const Dashboard = () => {
  const { currentUser } = useAuth();
  
  // Render different dashboard based on user role
  switch (currentUser.role) {
    case 'admin':
      return <AdminDashboard />;
    case 'adventurer':
      return <AdventurerDashboard messages={[]} lastSession="" />;
    case 'commoner':
    default:
      return <CommonerDashboard />;
  }
};

// Protected Route component
const ProtectedRoute = ({ children, requiredRole }) => {
  const { currentUser } = useAuth();
  
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && currentUser.role !== requiredRole && currentUser.role !== 'admin') {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

// Main App component
function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

// App content that depends on authentication state
function AppContent() {
  const { currentUser, loading } = useAuth();
  const [pageTransition, setPageTransition] = useState('fade-in');
  const location = useLocation();
  
  useEffect(() => {
    // Scroll to top whenever location changes
    window.scrollTo(0, 0);
  }, [location.pathname]);
  
  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
        <p>Entering the realm...</p>
      </div>
    );
  }

  return (
    <div className={`App ${pageTransition} ${currentUser?.role || ''}`}>
      {currentUser && (currentUser.role === 'adventurer' || currentUser.role === 'admin') && (
        <Navigation />
      )}
      <div className="content">
        <Routes>
          <Route path="/login" element={!currentUser ? <LoginPage /> : <Navigate to="/dashboard" />} />
          
          {/* Protected Routes */}
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          
          {/* Spells and Abilities Page */}
          <Route path="/spells-and-abilities" element={
            <ProtectedRoute requiredRole="adventurer">
              <SpellsAndAbilities />
            </ProtectedRoute>
          } />

          {/* First Veil Routes - Available to all logged-in users */}
          <Route path="/help" element={
            <ProtectedRoute>
              <Help />
            </ProtectedRoute>
          } />
          <Route path="/history" element={
            <ProtectedRoute>
              <History />
            </ProtectedRoute>
          } />
          <Route path="/house-rules" element={
            <ProtectedRoute>
              <HouseRules />
            </ProtectedRoute>
          } />

          {/* Deity Routes - Protected for Adventurer and Admin */}
          <Route path="/deity/*" element={
            <ProtectedRoute requiredRole="adventurer">
              <Routes>
                <Route path="caelumbris-shadowed" element={<CaelumbrisShadowed />} />
                <Route path="libris-knowledge" element={<LibrisKnowledge />} />
                <Route path="libris-knowledge-true" element={<LibrisKnowledgeTrue />} />
                <Route path="firstborn-soulbound" element={<FirstbornSoulbound />} />
                <Route path="nebula-mist-walker" element={<NebulaMistWalker />} />
                <Route path="queen-mab-frozen-veil" element={<QueenMabFrozenVeil />} />
                <Route path="queen-titania-wildgrowth" element={<QueenTitaniaWildgrowth />} />
                <Route path="sacrathor-dominion" element={<SacrathorDominion />} />
                <Route path="unbound" element={<Unbound />} />
                <Route path="valdis-justice" element={<ValdisJustice />} />
                <Route path="wildscar-ember-fury" element={<WildscarEmberFury />} />
                <Route path="libris-agreement" element={<LibrisAgreement />} />
              </Routes>
            </ProtectedRoute>
          } />

          {/* Additional Deities Routes - For nested paths */}
          <Route path="/deities/libris/useragreement" element={
            <ProtectedRoute requiredRole="adventurer">
              <LibrisAgreement />
            </ProtectedRoute>
          } />

          {/* Additional route for LibrisKnowledgeTrue */}
          <Route path="/deities/libris/knowledge/true" element={
            <ProtectedRoute requiredRole="adventurer">
              <LibrisKnowledgeTrue />
            </ProtectedRoute>
          } />

          {/* Pantheon Routes - Protected for Adventurer and Admin */}
          <Route path="/pantheon/*" element={
            <ProtectedRoute requiredRole="adventurer">
              <Routes>
                <Route path="caelumbris" element={<PantheonCaelumbris />} />
                <Route path="calanthar" element={<PantheonCalanthar />} />
                <Route path="leviathan" element={<PantheonLeviathan />} />
                <Route path="firstborn" element={<PantheonFirstborn />} />
                <Route path="libris" element={<PantheonLibris />} />
                <Route path="nebula" element={<PantheonNebula />} />
                <Route path="queen-mab" element={<PantheonQueenMab />} />
                <Route path="queen-titania" element={<PantheonQueenTitania />} />
                <Route path="sacrathor" element={<PantheonSacrathor />} />
                <Route path="valdis" element={<PantheonValdis />} />
                <Route path="wildscar" element={<PantheonWildscar />} />
              </Routes>
            </ProtectedRoute>
          } />

          {/* Classes Routes */}
          <Route path="/classes/ClassesBurningSeed" element={<ClassesBurningSeed />} />
          <Route path="/classes/ClassesDronewright" element={<ClassesDronewright />} />
          <Route path="/classes/ClassesGlacialHeart" element={<ClassesGlacialHeart />} />
          <Route path="/classes/ClassesPathOfWrath" element={<ClassesPathOfWrath />} />
          <Route path="/classes/ClassesPyromancer" element={<ClassesPyromancer />} />
          <Route path="/classes/ClassesSeraphicGuardian" element={<ClassesSeraphicGuardian />} />
          <Route path="/classes/ClassesSovereignZealot" element={<ClassesSovereignZealot />} />
          <Route path="/classes/ClassesUmbralInfiltrator" element={<ClassesUmbralInfiltrator />} />
          <Route path="/classes/ClassesWayOfTheLifedrinker" element={<ClassesWayOfTheLifedrinker />} />
          <Route path="/classes/ClassesWinterbornKnight" element={<ClassesWinterbornKnight />} />

          {/* Species Routes */}
          <Route path="/species/SpeciesGravewalker" element={<SpeciesGravewalker />} />
          <Route path="/species/SpeciesHumanity" element={<SpeciesHumanity />} />
          <Route path="/species/SpeciesLifedrinker" element={<SpeciesLifedrinker />} />
          <Route path="/species/SpeciesManaforged" element={<SpeciesManaforged />} />
          <Route path="/species/SpeciesScytherai" element={<SpeciesScytherai />} />
          <Route path="/species/SpeciesSeraphiend" element={<SpeciesSeraphiend />} />
          <Route path="/species/SpeciesSkinWalker" element={<SpeciesSkinWalker />} />
          <Route path="/species/SpeciesStarborn" element={<SpeciesStarborn />} />
          <Route path="/species/SpeciesSunseed" element={<SpeciesSunseed />} />
          <Route path="/species/SpeciesVermisCorpus" element={<SpeciesVermisCorpus />} />
          <Route path="/species/SpeciesWinterWitch" element={<SpeciesWinterWitch />} />

          {/* Default redirect */}
          <Route path="*" element={<Navigate to={currentUser ? "/dashboard" : "/login"} />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
