import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/Winter Witch/winterwitch.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesWinterWitch = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Winter Witch" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Winter Witch</h1>
          
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Origin</h2>
          <p>
            The Winter Witches first appeared during the first winter solstice after the Shattering, a time when a devastating winter storm swept across the Velum. This storm traveled with the devastating changing of seasons, leaving no part of the realm untouched. It brought icy destruction to lush lands and blanketed even the warmest regions in frost. In the coldest and harshest reaches, this unnatural storm gave birth to new beings—the Winter Witches. Emerging from the darkest caves and endless tundra, they were both natural protectors and predators, shaped by the storm's relentless power.
          </p>
        </div>

        <div className="content-block">
          <p>
            Winter Witches are said to originate from a sacred mountain range known as The Three Daughters, consisting of three massive peaks: The Mother, The Matron, and The Lady. These mountains are veiled in perpetual frost and are the seat of Queen Mab's domain. Legends speak of ancient storms and powerful winter magic emanating from these peaks. Each mountain symbolizes an aspect of winter—creation, preservation, and destruction. Though few have ventured there and returned, these peaks are believed to be the birthplace of the Winter Witches, a place where the original storm's power still lingers.
          </p>
        </div>

        <div className="content-block">
          <p>
            Unlike traditional societies, Winter Witches do not form structured communities or large gatherings. Their nature drives them to solitude or small, temporary covens. If not overseeing a domain of winter, they are driven by an innate compulsion to seek one. This search is not aimless; Winter Witches are drawn to regions they deem necessary for winter's presence. Once claimed, they dedicate themselves to maintaining the balance of the natural cycle, ensuring decay and renewal occur as intended.
          </p>
        </div>

        <div className="content-block">
          <h2>Appearance</h2>
          <h3>Icy Visage</h3>
          <p>
            Winter Witches exude an unnerving aura, marked by cold, calculating beauty. Their movements are precise, and their presence evokes the stillness of a winter storm—a calm that hides potent power. They are known for an almost supernatural stillness; they can remain as motionless as death itself, only to seemingly appear right in front of you in the blink of an eye, as though the frost carried them forward. Their eyes are piercing and seem to reflect the frost-laden landscapes they protect, occasionally shimmering with a faint, icy glow that betrays the depth of their magical connection.
          </p>
          <h3>Haunting Pallor</h3>
          <p>
            Their skin is unnaturally pale, often with grey or blue undertones that highlight their unyielding connection to the cold. Subtle markings, resembling cracked ice or frostbite scars, shimmer as though a thin layer of frost covers their flesh. When touched, their skin feels unnervingly tough and icy, more akin to polished marble than living tissue. These scars sometimes shift or grow faintly colder when the Witch draws upon her magic.
          </p>
          <h3>Prehensile Hair</h3>
          <p>
            Their hair is a tangled mass, appearing more like dark, frozen vines than typical strands. It writhes and twists with an eerie vitality, crackling faintly with blue energy. Colors range from ashen whites and icy blues to deep purples and midnight blacks. It is unkempt and unruly, its appearance dictated by its arcane use rather than aesthetics. In moments of heightened magic, their hair may move autonomously, frost trailing behind its strands, giving them an otherworldly and intimidating aura.
          </p>
        </div>

        <div className="content-block">
          <h2>Winter Witch Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase two different ability scores of your choice by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>Winter Witches mature at a slower pace than humans, reaching adulthood around the age of 25 and living for approximately 200 years.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>They tend towards lawful neutral, driven by a need for order and strategy above all else.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>Medium. Winter Witches vary in height and build, but most are between 5 feet and 6 feet tall.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 30 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Features</h3>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Frigid Assault</h3>
            </div>
            <p>Your connection to the cold allows you to wield it with precision. You know the ray of frost cantrip. Additionally, you can cast the ice knife spell a number of times equal to your proficiency bonus per long rest without requiring material components. After casting either spell, you can choose for the damage type to be piercing or cold. Starting at 5th level, when you score a critical hit with ray of frost or ice knife, the target's movement speed is reduced to 0 until the end of their next turn</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Prehensile Hair Mastery</h3>
            </div>
            <p>Your hair acts as an extension of your will. You can manipulate objects, open doors, or disarm traps with your hair as if using the mage hand cantrip. Your hair has a reach of 15 feet and can carry up to 5 pounds. As a bonus action, you can use your hair to make a number of melee spell attacks equal to your proficiency bonus against different targets, using your spellcasting modifier for attack and damage rolls. These attacks deal 1d4 cold damage on a hit. Starting at 8th level, these attacks also deal an additional 1d4 necrotic damage. As an action, you can use your hair as if casting mage hand.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Winter's Resilience</h3>
            </div>
            <p>You are resistant to cold damage but vulnerable to fire. As a reaction, when you take melee attack damage, you can emit a blast of freezing air, inflicting your level in cold damage on the attacker and reducing their movement speed by 10 feet until the end of their next turn. You may use this reaction a number of times equal to your proficiency bonus per long rest.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Frostbound Perception</h3>
            </div>
            <p>You have proficiency in the Insight and Survival skills. Additionally, you have advantage on Wisdom (Insight) and Wisdom (Survival) checks that rely on logic, pattern recognition, or inconsistencies in social or environmental interactions.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common, Sylvan, and one other language of your choice.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h2>Role playing</h2>
            </div>
          </div>

          <div className="trait">
            <p>As a Winter Witch, you are a master of frost and cold, embodying the stillness and harsh beauty of winter. Your power is both destructive and nurturing, freezing enemies in their tracks while protecting the fragile spark of life that endures through the bitterest cold. Your presence is often enigmatic, evoking awe and fear as you stride between life and death, warmth and ice. Whether you are a lone wanderer or a guardian of your frozen domain, your existence is a testament to the strength and resilience of winter.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Stoic:</strong> You maintain an air of calm and composure, rarely allowing your emotions to surface.</p>
            <p><strong>Calculated:</strong> You approach problems with precision, weighing every action and consequence before committing.</p>
            <p><strong>Austere:</strong> You live simply, valuing efficiency and necessity over frivolity.</p>
            <p><strong>Reflective:</strong> Like a frozen lake, you have a quiet depth, often contemplating the greater cycles of nature and existence.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Preservation:</strong> Winter preserves life by slowing its pace, and you strive to safeguard the balance of life and death.</p>
            <p><strong>Resilience:</strong> Like ice, you endure and adapt to challenges, growing stronger under pressure.</p>
            <p><strong>Balance:</strong> You understand that cold is not inherently evil, but a necessary force to temper and balance the world.</p>
            <p><strong>Legacy:</strong> You believe in leaving behind something enduring, much like how winter shapes the land for the spring to come.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>The Frostbound Lands:</strong> You are deeply connected to the icy landscapes you call home and feel responsible for their protection.</p>
            <p><strong>Winter's Memory:</strong> You revere the stories and traditions of winter, carrying their wisdom into your actions and teachings.</p>
            <p><strong>Kindred of the Cold:</strong> You feel a kinship with creatures or people who thrive in winter's embrace, be they wolves, frost giants, or fellow witches.</p>
            <p><strong>Frozen Betrayal:</strong> A past event where trust was broken in the coldest of circumstances drives your cautious nature and choices.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Aloof:</strong> Your calm and distant demeanor can make it difficult for others to connect with you on a personal level.</p>
            <p><strong>Inflexible:</strong> Like ice, you can be unyielding, struggling to adapt to rapid changes or unexpected outcomes.</p>
            <p><strong>Harsh:</strong> Your pursuit of efficiency and pragmatism can come across as cold or uncaring to others.</p>
            <p><strong>Lingering Shadows:</strong> You are haunted by an event where your power brought unintended harm, chilling your confidence and trust in yourself.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Winter Witch"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="WinterWitch.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesWinterWitch; 