import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import valdisImage from '../../assets/images/deities/hornshalo.jpg';
import './DeityPathPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const divineFlare = keyframes`
  0%, 100% { 
    text-shadow: 0 0 30px #FFF, 0 0 50px #FFF, 0 0 70px #FFD700;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  }
  50% { 
    text-shadow: 0 0 20px #8B0000, 0 0 35px #8B0000, 0 0 50px #FF0000;
    box-shadow: 0 0 30px rgba(139, 0, 0, 0.5);
  }
`;

const haloGlow = keyframes`
  0%, 100% { filter: drop-shadow(0 0 15px rgba(255, 215, 0, 0.8)); }
  50% { filter: drop-shadow(0 0 25px rgba(255, 215, 0, 0.4)); }
`;

const infernalPulse = keyframes`
  0% { background-position: 0% 0%; }
  100% { background-position: 200% 0%; }
`;

const StyledJusticePage = styled.div`
  .deity-path-title h1 {
    font-size: 4rem;
    background: linear-gradient(45deg, #FFD700, #FFFFFF, #8B0000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 4px;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        linear-gradient(90deg,
          transparent,
          rgba(255, 215, 0, 0.2),
          rgba(139, 0, 0, 0.2),
          transparent
        );
      background-size: 200% 100%;
      animation: ${infernalPulse} 3s linear infinite;
      z-index: -1;
    }

    &::after {
      content: '☩';
      position: absolute;
      top: -0.5em;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.5em;
      background: linear-gradient(to bottom, #FFD700, #FF0000);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: ${haloGlow} 2s ease-in-out infinite;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.8);
    border: 2px solid transparent;
    border-image: linear-gradient(45deg, #FFD700, #8B0000) 1;
    box-shadow: 
      0 0 20px rgba(255, 215, 0, 0.1),
      inset 0 0 30px rgba(139, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        linear-gradient(45deg,
          rgba(255, 215, 0, 0.05) 0%,
          rgba(139, 0, 0, 0.05) 50%,
          rgba(255, 215, 0, 0.05) 100%
        );
      pointer-events: none;
    }

    &:hover {
      border-image: linear-gradient(45deg, #8B0000, #FFD700) 1;
      box-shadow: 
        0 0 30px rgba(139, 0, 0, 0.2),
        inset 0 0 50px rgba(255, 215, 0, 0.1);
      transform: translateY(-2px);
    }
  }

  h2 {
    color: #FFD700;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #8B0000, #FFD700, #8B0000);
      animation: ${divineFlare} 3s infinite;
    }
  }

  h3 {
    background: linear-gradient(45deg, #FFD700, #8B0000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5rem;
    margin: 1rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      text-shadow: 
        0 0 10px rgba(255, 215, 0, 0.5),
        0 0 20px rgba(139, 0, 0, 0.3);
    }
  }

  p {
    color: #E5E4E2;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(229, 228, 226, 0.5);
  }

  strong {
    background: linear-gradient(45deg, #FFD700, #8B0000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    padding: 0 3px;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 215, 0, 0.1);
      transform: skew(-15deg);
      z-index: -1;
    }
  }

  ul li {
    color: #E5E4E2;
    position: relative;
    
    &::before {
      content: '⚔';
      color: #FFD700 !important;
      text-shadow: 0 0 5px rgba(139, 0, 0, 0.5);
      margin-right: 10px;
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #FFD700, #8B0000);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 
        0 0 10px rgba(255, 215, 0, 0.5),
        0 0 20px rgba(139, 0, 0, 0.3);
    }
  }

  .requirements-section {
    border-image: linear-gradient(45deg, #FFD700, #8B0000) 1;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        repeating-linear-gradient(
          45deg,
          transparent,
          transparent 10px,
          rgba(255, 215, 0, 0.02) 10px,
          rgba(139, 0, 0, 0.02) 20px
        );
      pointer-events: none;
    }
  }

  .veiled-paths h2 {
    background: linear-gradient(45deg, #FFD700, #8B0000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    
    &::after {
      display: none;
    }
  }

  .veiled-link {
    color: #E5E4E2 !important;
    transition: all 0.3s ease;

    &:hover {
      background: linear-gradient(45deg, #FFD700, #8B0000);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0 0 8px rgba(255, 215, 0, 0.4);
    }
  }
`;

const ValdisJustice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // The sequence of pages for navigation - following dropdown menu order
  const pageSequence = [
    '/deities/caelumbris/shadowed',
    '/deities/firstborn/soulbound',
    '/deities/libris/knowledge',
    '/deities/nebula/mistwalker',
    '/deities/queenmab/frozenveil',
    '/deities/queen-titania/wildgrowth',
    '/deities/sacrathor/dominion',
    '/deities/unbound',
    '/deities/valdis/justice',
    '/deities/wildscar/emberfury'
  ];

  // Required minimum distance traveled to be considered a swipe
  const minSwipeDistance = 50;

  // Handle navigation based on swipe direction
  const handleSwipe = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    // Get current page index in the sequence
    const currentIndex = pageSequence.findIndex(page => page === location.pathname);
    
    // Navigate based on swipe direction
    if (isLeftSwipe) {
      // Navigate to next page (or first if at end)
      const nextIndex = (currentIndex + 1) % pageSequence.length;
      navigate(pageSequence[nextIndex]);
    } else if (isRightSwipe) {
      // Navigate to previous page (or last if at first)
      const prevIndex = (currentIndex - 1 + pageSequence.length) % pageSequence.length;
      navigate(pageSequence[prevIndex]);
    }
  };

  // Touch event handlers
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
  };

  // Mouse event handlers for desktop testing
  const onMouseDown = (e) => {
    setTouchEnd(null);
    setTouchStart(e.clientX);
  };

  const onMouseMove = (e) => {
    if (!touchStart) return;
    setTouchEnd(e.clientX);
  };

  const onMouseUp = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
    setTouchStart(null);
    setTouchEnd(null);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return (
    <StyledJusticePage 
      className="deity-path-page"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <div className="deity-path-hero">
        <img 
          src={valdisImage} 
          alt="Valdís: Justice Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Valdís: The Path of Justice</h1>
        </div>
      </div>

      <div className="content-block requirements-section">
        <div className="edicts">
          <h2>Introduction</h2>
          <p>
            To walk the Path of Justice is to become an unyielding force of balance, an arbiter of righteousness, and a guardian against tyranny. Devotion to Valdís is not a matter of blind faith but a relentless pursuit of truth and the unwavering will to uphold justice. Those who take this path do not hesitate in the face of corruption; they strike with purpose, shield the innocent, and deliver judgment where it is due.
          </p>
          <p>
            The followers of Valdís do not stand idle. They are defenders, warriors, and adjudicators, whose very presence demands fairness in all things. To fail in their duty is to betray their calling, and thus they walk a road where mercy and vengeance weigh equally in the balance.
          </p>
        </div>
      </div>

      <div className="content-block requirements-section">
        <div className="edicts">
          <h2>Edicts</h2>
          <ul>
            <li>Deliver justice tempered with mercy, ensuring the innocent are shielded from harm and the scales of fairness are balanced.</li>
            <li>Balance punishment with understanding, seeking to reform rather than destroy whenever possible.</li>
            <li>Act selflessly as a protector for those in need, sacrificing for the greater good when necessary.</li>
          </ul>
        </div>
        
        <div className="requirements">
          <h2>Requirements</h2>
          <ul>
            <li>Offer aid and comfort to those who have suffered injustice, providing them with sanctuary and support.</li>
            <li>Never hesitate to defend the innocent, even if it means putting yourself in harm's way.</li>
            <li>Never lie or deceive, as honesty and transparency are the cornerstones of true justice.</li>
          </ul>
        </div>
      </div>

      <div className="content-block dark-block">
        <h2 className="blue-text">Walking the Path of Justice</h2>
        <p>
          Following the Path of Justice grants practitioners the ability to balance the scales of law and compassion. Valdís empowers her followers to protect the innocent, unify the broken, and deliver righteous judgment. Practitioners wield the radiant light of justice, tempered with mercy, to heal and shield allies while delivering divine retribution to the wicked.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Valdís: The Shield of Justice</h3>
        </div>
        <p>
          Valdís stands as the essence of protection, sanctuary, self-sacrifice, retribution, and vengeance. Her domain is a raging storm of reflections—all the unresolved and unbalanced wrongs of the world. It is said that the storm will only calm on the day after the world ends, symbolizing her eternal dedication to justice and balance.
        </p>
        <p>
          Valdís stands as the ultimate arbiter of fairness and protection. Followers of Valdís see her as the embodiment of law and compassion, teaching that true justice requires both accountability and understanding.
        </p>
        <p>
          Legends tell of Valdís forging the Radiant Shield, an artifact said to hold the light of the first sunrise, symbolizing her unyielding commitment to protecting the innocent and punishing the guilty. Her followers strive to emulate her unwavering sense of duty, becoming living embodiments of her divine principles.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Valdís's Domain</h3>
        </div>
        <p>
          Valdís's realm is a turbulent expanse where golden lightning streaks across darkened skies, illuminating towering spires of light and shadow. At its heart churns the Eternal Storm, a manifestation of every injustice left unresolved, howling with the echoes of those who seek retribution. The Hall of Judgments stands unwavering amidst the chaos, a sanctum of balance where the weight of every decision is measured and made clear.
        </p>
        <p>
          Despite its tempestuous nature, the domain emanates an unshakable resolve, offering solace to those who seek redemption and striking fear into those who evade accountability. It is a place where every act is seen, every deed is weighed, and where Valdís herself wields the power to restore equilibrium to a fractured world.
        </p>
      </div>

      <h2>Levels and Abilities</h2>
      <div className="trait">
        <div className="trait-header">
          <h3>Level 3: The First Oath</h3>
        </div>
        <p>Your journey into justice begins with unwavering resolve, granting you the ability to intervene in moments of peril and shield those in need.</p>

        <div className="trait">
          <div className="trait-header">
            <h3>Radiant Shield</h3>
          </div>
          <p>As an action, you can summon a shield of golden light that grants an ally the effects of the Sanctuary spell and restores 1 hit point at the start of their turn. The shield lasts a number of rounds equal to your level or until the target makes an attack or casts a spell that affects an enemy. You can use this feature a number of times equal to your proficiency bonus per long rest.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Scales of Judgment</h3>
          </div>
          <p>You can form a bond with a number of allies equal to your proficiency bonus. While within 30 feet of a bonded ally, you can use your reaction to teleport to their location, taking the damage they would have received. The attacker takes radiant damage equal to half the absorbed damage, ignoring resistances.</p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Level 5: Smite of Judgment</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Judgment Strike</h3>
          </div>
          <p>When you hit a creature with a melee weapon attack, you can expend a use of this feature to deal an additional 1d8 radiant and fire damage. This damage increases to 2d8 at 10th level and 3d8 at 15th level. You can use this feature a number of times equal to your proficiency bonus per long rest.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Unyielding Endurance</h3>
          </div>
          <p>Your Constitution score increases by 2, reinforcing your resilience in battle.</p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Level 10: Radiant Sheath</h3>
        </div>
        <p>You become a living bastion of light, encasing yourself and your weapons in divine energy.</p>

        <div className="trait">
          <div className="trait-header">
            <h3>Radiant Armor</h3>
          </div>
          <p>As a bonus action, you can envelop your armor in radiant energy, granting a +2 bonus to AC, and if you are using a shield, it also gains a +1 bonus to AC.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Radiant Weapon</h3>
          </div>
          <p>As a bonus action, you can sheath your weapon in divine energy, granting a +3 bonus to attack rolls and an additional 1d8 damage (half radiant, half fire).</p>
        </div>

        <p>Once activated, these effects last for 1 minute. You can use this feature a number of times equal to your proficiency bonus per long rest.</p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Level 15: True Seraphiend Transformation</h3>
        </div>
        <p>At the pinnacle of your path, you ascend beyond mortal limitations and become a True Seraphiend, a celestial warrior of balance and judgment.</p>

        <div className="trait">
          <div className="trait-header">
            <h3>Aura of Valor</h3>
          </div>
          <p>You emit a constant aura of divine energy in a 30-foot radius. A creature that enters the aura for the first time on a turn gains a +2 bonus to all saving throws and temporary hit points equal to your proficiency bonus for 1 minute. A creature can only benefit from this effect once per long rest.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Seraphiend Form</h3>
          </div>
          <ul className="specializations-list">
            <li>Your size increases to Large (8-12 feet tall).</li>
            <li>You grow gold or platinum horns and a matching radiant halo.</li>
            <li>You sprout angelic wings, gaining a flying speed equal to your walking speed.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Seraphiend Ascension</h3>
          </div>
          <p>You gain the following traits:</p>
          <ul className="specializations-list">
            <li><strong>Celestial Resistance:</strong> You gain resistance to radiant and fire damage.</li>
            <li><strong>Angelic Vision:</strong> You gain darkvision out to 120 feet, allowing you to see in magical darkness.</li>
            <li><strong>Divine Presence:</strong> Creatures of your choice within 10 feet must make a Wisdom saving throw (DC = 8 + your proficiency bonus + your Charisma modifier) or be frightened for 1 minute.</li>
            <li><strong>Radiant Smite (Upgraded):</strong> When you use Judgment Strike, you deal 3d8 radiant and fire damage, and if the target fails a Constitution saving throw (DC = 8 + your proficiency bonus + your Charisma modifier), it is blinded until the end of its next turn.</li>
          </ul>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Path Summary</h3>
        </div>
        <p>
          The Path of Justice is not for the weak-hearted; it demands unwavering conviction and a sense of balance. Those who walk this path are more than warriors—they are the physical embodiment of judgment and protection, standing against all who seek to oppress and corrupt.
        </p>
        <p>
          At their peak, they transcend mortality, becoming Seraphiends—divine executioners of justice, radiating power and striking with the full authority of Valdís. Their presence alone commands order, and their wrath is reserved for those who dare defy the scales of balance.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Roleplaying the Path of Justice</h3>
        </div>
        <p>
          Followers of Valdís are defined by their unwavering commitment to fairness and protection. They act as shields for the vulnerable and as swords of accountability for the wicked. Valdís' followers understand that true justice is not blind but sees with clarity, balancing accountability with compassion.
        </p>
        <p>
          Choosing this path is a commitment to uphold the principles of law, fairness, and community. Practitioners of the Path of Justice embody the radiant light of Valdís, guiding others toward harmony and delivering judgment with both strength and grace.
        </p>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={valdisImage} 
              alt="Valdís: Justice Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={valdisImage}
              download="Valdis.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </StyledJusticePage>
  );
};

export default ValdisJustice;