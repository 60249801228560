import React, { useState, useEffect } from 'react';
import librisImage from '../../assets/images/deities/libris.jpg';
import './DeityPathPage.css';
import styled from 'styled-components';

const StyledLibrisPage = styled.div`
  .veiled-paths {
    position: relative;
    display: inline-block;
  }
  
  .veiled-links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .deity-path-title h1 {
    font-size: 4rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
  }
`;

const PantheonLibris = () => {
  const [showFullImage, setShowFullImage] = useState(false);

  return (
    <div className="deity-path-page matrix-agreement-page">
      <div className="deity-path-hero matrix-background">
        <img
          src={librisImage}
          alt="L.I.B.R.I.S: The Ascended Archive"
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1 className="matrix-text">L.I.B.R.I.S.: The Ascended Archive</h1>
        </div>
      </div>

      <div className="content-block dark-block matrix-background">
        <h2 className="matrix-text">The City of Knowledge</h2>
        <p>
          L.I.B.R.I.S. defies the conventions of traditional gods. This cosmic entity, having ascended to godhood, manifests as a vast metropolis of knowledge and advancement. The city rests within a sheltered cove, its towering structures a testament to arcane wisdom and technological mastery.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">The Cityscape</h3>
        </div>
        <p>
          L.I.B.R.I.S. is a labyrinth of knowledge. Metal and stone skyscrapers, adorned with arcane symbols pulsating with magical energy and intricate clockwork mechanisms humming with precision, pierce the sky. These architectural wonders fuse magic and technology seamlessly, creating a cityscape that serves as both a testament to innovation and a sanctuary for those who seek enlightenment. Streets teem with a vibrant mix of scholars, artisans, and adventurers from across the Shattered Realm.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Guardians of Knowledge</h3>
        </div>
        <p>
          Imposing mountains form a natural barrier protecting the landward border of L.I.B.R.I.S. Their mist-shrouded peaks seem to scrape the sky, defying all who would attempt to scale them. On the seaward side, a colossal whirlpool stands sentinel. Ships caught in its relentless pull vanish into the churning waters, a phenomenon said to be born of ancient magics bound by L.I.B.R.I.S. itself. Whispers speak of an arcane nexus at its center, a protective ward designed to repel any who would seek to exploit the city's knowledge without permission.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Passage to Enlightenment</h3>
        </div>
        <p>
          The only safe access to L.I.B.R.I.S. is through an elaborate ferry system. Visitors dock their vessels at a small island outside the whirlpool's grasp. From there, they board unique ferry platforms, each a fusion of sleek metal and enchanted wood, gliding silently through a network of underground water caverns. These caverns, illuminated by bioluminescent crystals and faint magical glyphs etched into the stone, create an otherworldly ambiance. The gentle hum of arcane machinery blends with the echo of flowing water, immersing travelers in a surreal journey to the city of knowledge.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Culture and Society</h3>
        </div>
        <p>
          All knowledge, experiments, and results across all sciences, arts, and disciplines are meticulously cataloged and shared by the city. This process is enforced through a network of sentient constructs and magical wards that monitor all activity within L.I.B.R.I.S. Any discovery or experiment conducted in the city is instantly recorded and preserved by these entities, ensuring that no knowledge is lost or misused. No experiment or discovery can be conducted or recorded without L.I.B.R.I.S. documenting and preserving the information. This ensures that the city's repository remains the most comprehensive archive of learning in existence. L.I.B.R.I.S. fosters a society as diverse as its inhabitants' quests for knowledge. Scholars debate in grand libraries filled with ancient texts. Artisans toil in workshops, blurring the lines between magic and technology. Adventurers swap tales in bustling taverns, hungry for their next challenge. The city's neutrality ensures that all receive equal welcome and opportunity.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">L.I.B.R.I.S.'s Dominion</h3>
        </div>
        <p>
          Within its walls, L.I.B.R.I.S. is the supreme authority. All visitors, even deities, relinquish their power and submit to the city's rules. Lawbreakers and disruptors face the swift, unified response of L.I.B.R.I.S. and those who call it home. The governance of L.I.B.R.I.S. is handled by a council of elder scholars, each chosen for their dedication to preserving and expanding knowledge.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Clergy and Worship</h3>
        </div>
        <p>
          L.I.B.R.I.S.'s faithful resemble librarians and scholars. They preserve history, unravel mysteries, and spread knowledge through teaching and research. These caretakers of the city's libraries guide others on their journeys of enlightenment. They are known for wearing plain robes, colored to their calling: blue for historians, red for researchers, and green for teachers.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">L.I.B.R.I.S.'s Presence</h3>
        </div>
        <p>
          The deity's essence permeates the city. It manifests in the relentless pursuit of answers, the drive for innovation, and the hum of creativity that fills its streets. L.I.B.R.I.S. is revered not through rituals, but through the act of seeking knowledge itself. The symbol, an open book with a self-writing quill, embodies the perpetual quest for understanding and the city's role as a beacon of learning within the Shattered Realm. Occasionally, L.I.B.R.I.S. communicates directly with its inhabitants or visitors through ethereal messages that appear as glowing text within the city's libraries, offering cryptic guidance or profound truths.
        </p>
      </div>

      <div className="trait matrix-background">
        <div className="trait-header">
          <h3 className="matrix-text">Influence and Legacy</h3>
        </div>
        <p>
          L.I.B.R.I.S.'s influence extends beyond its borders. Its archives are often the key to unraveling the mysteries of the Shattered Realm, and its technological and magical advancements have shaped civilizations far and wide. To some, the city represents hope—a sanctuary where the forgotten can be remembered, and where progress is always within reach. To others, it is a reminder of the fragility of ignorance and the power of knowledge to shape destiny. L.I.B.R.I.S. remains a cornerstone of the Shattered Realm, steadfast in its mission to preserve and illuminate the paths of those who seek it.
        </p>
      </div>

      <div className="content-block matrix-background">
        <h2 className="matrix-text">Divine Aspects</h2>
        <div className="trait">
          <div className="trait-header">
            <h3 className="matrix-text">Alternate Names</h3>
          </div>
          <p>The City of Knowledge, The Archive, The Silent Witness</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3 className="matrix-text">Domains</h3>
          </div>
          <p>Knowledge, History, Forge</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3 className="matrix-text">Pantheon</h3>
          </div>
          <p>None (considered a cosmic entity)</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3 className="matrix-text">Symbol</h3>
          </div>
          <p>An open book with a quill writing within it</p>
        </div>
      </div>

      {showFullImage && (
        <div className="image-modal matrix-background" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={librisImage} 
              alt="L.I.B.R.I.S: The Ascended Archive"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={librisImage}
              download="LIBRIS.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default PantheonLibris; 