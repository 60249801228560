import React, { useState, useEffect } from 'react';
import droneWrightImage from '../../assets/images/subclasses/Dronewright/dronewright.jpg';
import './ClassesPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const ClassesDronewright = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  
  const navigate = useNavigate();
  const location = useLocation();

  // Define the sequence of pages for navigation
  const pageSequence = [
    '/classes/ClassesBurningSeed',
    '/classes/ClassesDronewright',
    '/classes/ClassesGlacialHeart',
    '/classes/ClassesPathOfWrath',
    '/classes/ClassesPyromancer',
    '/classes/ClassesSeraphicGuardian',
    '/classes/ClassesSovereignZealot',
    '/classes/ClassesUmbralInfiltrator',
    '/classes/ClassesWayOfTheLifedrinker',
    '/classes/ClassesWinterbornKnight'
  ];

  // Handle swipe navigation
  const handleSwipe = () => {
    if (!touchStart || !touchEnd) return;
    
    const minSwipeDistance = 50;
    const swipeDistance = touchStart - touchEnd;
    const currentPath = location.pathname;
    const currentIndex = pageSequence.findIndex(path => path === currentPath);
    
    console.log("Current path:", currentPath);
    console.log("Current index:", currentIndex);
    
    if (Math.abs(swipeDistance) > minSwipeDistance) {
      // Swiping from right to left (next)
      if (swipeDistance > 0 && currentIndex < pageSequence.length - 1) {
        console.log("Navigating to:", pageSequence[currentIndex + 1]);
        navigate(pageSequence[currentIndex + 1]);
      }
      // Swiping from left to right (previous)
      else if (swipeDistance < 0 && currentIndex > 0) {
        console.log("Navigating to:", pageSequence[currentIndex - 1]);
        navigate(pageSequence[currentIndex - 1]);
      }
    }
    
    setTouchStart(null);
    setTouchEnd(null);
  };

  // Touch event handlers
  const onTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };
  
  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };
  
  const onTouchEnd = () => {
    handleSwipe();
  };
  
  // Mouse event handlers for desktop testing
  const onMouseDown = (e) => {
    setTouchStart(e.clientX);
  };
  
  const onMouseMove = (e) => {
    if (touchStart) {
      setTouchEnd(e.clientX);
    }
  };
  
  const onMouseUp = () => {
    handleSwipe();
  };

  return (
    <div 
      className="pantheon-page"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <div className="pantheon-hero">
        <img 
          src={droneWrightImage} 
          alt="Dronewright" 
          className="pantheon-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="pantheon-details">
          <h1>Dronewright</h1>
        </div>
      </div>

      <div className="content-block">
        <h2>Overview</h2>
        <p>
          Artificers who follow the path of the Dronewright pioneer the fusion of advanced technology with arcane traditions, their origins as diverse as the drones they construct. Some Dronewrights hail from steampunk worlds where clockwork mechanisms are imbued with magic, while others come from futuristic realities where technology and magic coexist seamlessly in space faring civilizations.
        </p>
        <p>
          Their journey often begins with the discovery of an enigmatic drone or the invention of one from scratch, sparking a lifelong pursuit of innovation. Regardless of origin, all Dronewrights share a deep understanding of how to weave the mechanical and magical into one cohesive force. Their creations act as extensions of their ingenuity, enhancing their physical prowess, serving as formidable weapons, or providing unparalleled utility.
        </p>
        <p>
          Despite the diversity in style and function—from rustic, gear-driven automatons to sleek, nanite-infused constructs—Dronewright drones share a unified design ethos: adaptability and synergy with their creator. This subclass celebrates the limitless potential of merging imagination with invention, granting Artificers the tools to reshape the battlefield, solve intricate problems, and leave their indelible mark on the world.
        </p>
      </div>

      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={droneWrightImage} 
                alt="Dronewright"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={droneWrightImage}
                download="Dronewright.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassesDronewright; 