import React, { useState, useRef, useEffect } from 'react';
import caelumbrisImage from '../../assets/images/races/SkinWalker/skinwalker.jpg';
import './SpeciesPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const SpeciesSkinWalker = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="speciespage">
      <div className="speciespage-hero">
        <img 
          src={caelumbrisImage} 
          alt="Skinwalker" 
          className="speciespage-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="speciespage-title">
          <h1>Skinwalker</h1>
          
        </div>
      </div>

      <div className="speciespage-details">
        <div className="content-block">
          <h2>Race Overview</h2>
          <p>
            SkinWalkers are nomadic survivors, forged by the relentless conditions of an endless winter. Once guardians of the natural world, they followed the seasonal migrations of prey, guided by ancient shamanic rituals and their profound connection to the wild. After their world was corrupted by entities from beyond their reality, their essence became entwined with the frost and the hunt, forever altering their nature. Today, their ability to transform into Winter Wolves serves as both a symbol of their resilience and a testament to their ongoing struggle to reclaim their heritage.
          </p>
        </div>

        <div className="content-block">
          <h2>History</h2>
          <p>
            The SkinWalkers originate from a world defined by tribal nomadism, untouched by cities or advanced technology. Their lives were deeply intertwined with the spirits of the land, guided by shamans who communed with these ethereal beings. A thousand years before the Shattering, a shaman's ambition led to a catastrophic mistake. In an attempt to unlock forbidden knowledge and strengthen his tribe's dominion, he opened a portal to the outer planes. From this breach poured entities from beyond existence, enslaving the SkinWalkers and transforming their once-pristine tundra into a desolate wasteland of jagged ice and sulfurous rifts. Sacred sites were corrupted, and the tribes were bound in servitude, driven to the brink of collapse by their otherworldly masters.
          </p>
          <p>
            Over centuries of enslavement, the SkinWalkers' essence began to shift. Their blood became tainted, granting them the ability to transform into Winter Wolves—creatures embodying the cold, harsh power of the outer planes. These transformations became both a symbol of their corruption and a tool of resistance. SkinWalkers used their newfound abilities to conduct guerrilla warfare against their oppressors, leveraging their primal instincts and resilience through generations of struggle. Today, they remain nomadic survivors, ever wary of the world around them.
          </p>
        </div>

        <div className="content-block">
          <h2>Appearance</h2>
          <p>
            In their humanoid form, SkinWalkers possess a primal, lupine appearance, marked by fur-like patterns on their skin and piercing, ice-blue eyes. Their hair often bears frost-like streaks, and their robust build reflects a lifetime of endurance and movement. In Winter Wolf form, they resemble immense wolves with snow-white coats. Their breath emerges in visible mists, and their glowing eyes betray an otherworldly heritage that is both beautiful and terrifying.
          </p>
        </div>

        <div className="content-block">
          <h2>Personality</h2>
          <p>
            SkinWalkers are fiercely loyal to their tribe or group, valuing the bonds forged through shared hardship. Their history of enslavement and betrayal has made them cautious and distrustful of outsiders, though they deeply respect strength and skill. Storytelling and shamanic rituals are integral to their culture, preserving their history and identity.
          </p>
        </div>

        <div className="content-block">
          <h2>Society</h2>
          <p>
            SkinWalker society is deeply rooted in their nomadic lifestyle and shamanic traditions. Tribes are led by elders and shamans, who guide the community through their wisdom and connection to the spirits. Rituals and ceremonies are central to their way of life, honoring the natural world and the ancestors who came before them. SkinWalkers value strength, resilience, and the ability to adapt to harsh conditions. Their communities are tightly knit, with each member playing a crucial role in the tribe's survival.
          </p>
        </div>

        <div className="content-block">
          <h2>Naming Conventions</h2>
          <p>
            SkinWalker names evoke the raw power and resilience of nature. Examples include Frostfang, Snowshadow, and Icehowl. Tribal names often reflect their struggles and ancestry, such as "Of the Frozen Hunt" or "Born of Winter's Wrath," titles that honor their heritage and unyielding spirit.
          </p>
        </div>

        <div className="content-block">
          <h2>Racial Traits</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Languages</h3>
            </div>
            <p>You can speak, read, and write Common and Abyssal. Additionally, in Winter Wolf form, you can communicate with other Winter Wolves.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ability Score Increase</h3>
            </div>
            <p>Increase two different ability scores of your choice by 2.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Age</h3>
            </div>
            <p>SkinWalkers mature quickly, reaching adulthood by age 15. They live around 80 years, though their rugged lifestyle often shortens their lifespan.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Alignment</h3>
            </div>
            <p>SkinWalkers are typically neutral, prioritizing survival and loyalty to their kin above all else.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Size</h3>
            </div>
            <p>SkinWalkers are Large creatures, reflecting their immense physical stature and presence. As Large creatures, they occupy larger spaces, have increased carrying capacity, and can wield oversized weapons more effectively. However, they may face restrictions when navigating narrow passages, using equipment designed for Medium creatures, or entering standard-sized buildings.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Speed</h3>
            </div>
            <p>Your base walking speed is 50 feet.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Natural Armor</h3>
            </div>
            <p>Your thick, frost-touched hide grants you an unarmored AC of 16 + your Dexterity modifier, applicable in both humanoid and Winter Wolf forms. This AC does not stack with shields or magical armor effects but can benefit from spells or features that enhance natural armor.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Natural Weapons – Claws and Bite</h3>
            </div>
            <p>Your natural claws and bite make you a formidable foe in melee combat:</p>
            <p>Claws (Natural Weapon): Melee Weapon Attack, deals 1d6 slashing damage and 1d8 cold damage (scales to 1d10 at level 5, 1d12 at level 10, and 2d8 at level 18).</p>
            <p>Bite Attack: When you successfully hit with a melee attack using a light weapon or your claws, you can make a bonus bite attack. The bite deals 1d4 piercing damage and adds 1d6 cold damage (scales to 1d8 at level 5, 1d10 at level 10, and 2d6 at level 18).</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Cold Resistance</h3>
            </div>
            <p>You have resistance to cold damage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Keen Senses</h3>
            </div>
            <p>You possess superior darkvision out to 120 feet and advantage on Perception checks that rely on sight, sound, or smell.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Hunter's Survival</h3>
            </div>
            <p>You are proficient in the Survival skill and have advantage on checks made to track creatures.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Polymorph Resistance</h3>
            </div>
            <p>You are immune to the polymorph spell and other similar effects that would alter your form unless you allow it.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Shapeshift – Winter Wolf Form</h3>
            </div>
            <p>As an action, you can transform into a Winter Wolf a number of times per day equal to your proficiency bonus.</p>
            
            <div className="trait-subsection">
              <h4>Transformation Rules</h4>
              <ul className="trait-list">
                <li>You retain your racial traits but gain the abilities of the Winter Wolf.</li>
                <li>You can remain in this form until you revert to your humanoid form as a bonus action or are incapacitated.</li>
                <li>You are unable to speak but can communicate with other Winter Wolves.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="content-block">
          <h2>Role Playing</h2>
          <p>As a SkinWalker, you are a creature of survival and resilience, shaped by the harsh tundras and unyielding conditions of your ancestral homeland. Your dual nature as a humanoid and a Winter Wolf reflects the deep connection you hold with the primal forces of nature and the spirits of the hunt. Whether you embrace your feral instincts or strive to maintain balance with the remnants of your humanity, you are a living embodiment of adaptation and strength. The hardships of your history have forged a fierce loyalty to your kin and an unrelenting determination to endure, no matter the odds.</p>

          <div className="trait">
            <div className="trait-header">
              <h3>Personality Traits</h3>
            </div>
            <p><strong>Loyal:</strong> You are fiercely devoted to your tribe or companions, valuing the bonds formed through shared struggles.</p>
            <p><strong>Pragmatic:</strong> Survival is your priority, and you approach challenges with practicality and efficiency.</p>
            <p><strong>Wary:</strong> Your history of betrayal and enslavement has left you cautious, making it difficult for you to trust outsiders.</p>
            <p><strong>Instinctual:</strong> You rely on your sharp instincts and primal intuition to navigate the world and its dangers.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Ideals</h3>
            </div>
            <p><strong>Survival:</strong> You believe in doing whatever it takes to endure, adapting to challenges and overcoming obstacles.</p>
            <p><strong>Strength:</strong> Power and resilience are the ultimate virtues, and you strive to embody these traits in all you do.</p>
            <p><strong>Tradition:</strong> You honor the rituals and shamanic practices of your ancestors, preserving their legacy through your actions.</p>
            <p><strong>Freedom:</strong> You value independence and despise anything that threatens to chain you or your kin again.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Bonds</h3>
            </div>
            <p><strong>The Pack:</strong> Your tribe is your family, and you will go to any lengths to protect and support them.</p>
            <p><strong>Ancestral Spirits:</strong> You feel a profound connection to the spirits of your ancestors, who guide and inspire you.</p>
            <p><strong>The Hunt:</strong> The thrill of the hunt is sacred to you, representing both survival and the eternal bond between predator and prey.</p>
            <p><strong>The Betrayal:</strong> A lingering memory of the betrayal that led to your people's enslavement fuels your drive to protect and reclaim your heritage.</p>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Flaws</h3>
            </div>
            <p><strong>Distrustful:</strong> Your caution can border on paranoia, causing you to view others with suspicion, even when unnecessary.</p>
            <p><strong>Aggressive:</strong> You are quick to resort to violence or intimidation, especially when you feel cornered or threatened.</p>
            <p><strong>Isolationist:</strong> You struggle to open up to others outside your tribe, often alienating potential allies.</p>
            <p><strong>Wild Impulse:</strong> The feral instincts of your Winter Wolf form can sometimes overwhelm your rational judgment.</p>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <div className="modal-content">
              <img 
                src={caelumbrisImage} 
                alt="Skinwalker"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={caelumbrisImage}
                download="Skinwalker.jpg"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesSkinWalker; 