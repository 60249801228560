import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import './CommonerDashboard.css';

const CommonerDashboard = () => {
  const { currentUser, logout } = useAuth();
  const [requestSent, setRequestSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiDetails, setApiDetails] = useState(null);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';

  useEffect(() => {
    // Debug info for API configuration
    const details = {
      apiUrl: API_URL,
      hostname: window.location.hostname,
      hasToken: !!localStorage.getItem('auth_token'),
      hasUser: !!currentUser,
      userId: currentUser?.id,
      userRole: currentUser?.role
    };
    console.log('API Configuration:', details);
    setApiDetails(details);
  }, [API_URL, currentUser]);

  const handleRequestAccess = async () => {
    setLoading(true);
    setError(null);
    
    if (!currentUser || !currentUser.id) {
      setError('User data is missing. Please log out and log in again.');
      setLoading(false);
      return;
    }

    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('Authentication token is missing. Please log out and log in again.');
      setLoading(false);
      return;
    }
    
    try {
      // Cleaner approach using the new simplified endpoint
      console.log('Sending role request for user ID:', currentUser.id);
      
      const response = await fetch(`${API_URL}/simple-role-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: currentUser.id,
          requestedRole: 'adventurer'
        })
      });
      
      console.log('Response status:', response.status);
      
      if (response.ok) {
        setRequestSent(true);
        console.log('Request successful');
      } else {
        let errorMessage = `Server error: ${response.status}`;
        try {
          const errorData = await response.json();
          console.error('Error response data:', errorData);
          errorMessage = errorData.message || errorMessage;
        } catch (parseError) {
          console.error('Could not parse error response:', parseError);
        }
        setError(errorMessage);
      }
    } catch (err) {
      console.error('Network error details:', err);
      setError('Network error. Please check your connection and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="commoner-dashboard">
      <div className="dashboard-header">
        <h1>First Veil</h1>
        <button 
          className="logout-button"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
      
      <div className="dashboard-content">
        <div className="access-request-container">
          <h2 className="access-title">Adventurer Access</h2>
          {error && (
            <div className="error-message">
              {error}
            </div>
          )}
          {apiDetails && !currentUser?.id && (
            <div className="error-message">
              User ID is missing. Cannot send request.
            </div>
          )}
          {requestSent ? (
            <p className="request-status">Request sent</p>
          ) : (
            <button 
              className="request-access-button"
              onClick={handleRequestAccess}
              disabled={loading || !currentUser?.id}
            >
              {loading ? 'Sending...' : 'Request Access'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonerDashboard; 