import React from 'react';

// Direct imports of JSON files
import chingPowData from '../assets/data/Ching Pow030825.json';
import korbenData from '../assets/data/Korben030825.json';
import ornyData from '../assets/data/ornyturlcise030825.json';
import texData from '../assets/data/texavery030825.json';

/**
 * Character Data Service
 * 
 * Provides utilities for fetching and normalizing character data from JSON files.
 * Handles the complex character sheet JSON format and provides clean access methods.
 */

// Map of character JSON keys to their imported data
const CHARACTER_DATA = {
  chingPow: chingPowData,
  korben: korbenData,
  orny: ornyData,
  tex: texData
};

// A cache to avoid repeated processing
let characterDataCache = {};

/**
 * Loads character data for all characters or a specific character
 * @param {string} [characterKey] - Optional specific character to load
 * @returns {Promise<Object>} - Character data object
 */
export const loadCharacterData = async (characterKey = null) => {
  try {
    // If a specific character is requested
    if (characterKey) {
      if (characterDataCache[characterKey]) {
        return characterDataCache[characterKey];
      }
      
      const rawData = CHARACTER_DATA[characterKey];
      if (!rawData) {
        throw new Error(`No data found for character key: ${characterKey}`);
      }
      
      const normalizedData = normalizeCharacterData(rawData);
      characterDataCache[characterKey] = normalizedData;
      return normalizedData;
    }
    
    // Load all characters
    const results = [];
    for (const [key, rawData] of Object.entries(CHARACTER_DATA)) {
      if (characterDataCache[key]) {
        results.push({ key, data: characterDataCache[key] });
        continue;
      }
      
      try {
        const normalizedData = normalizeCharacterData(rawData);
        characterDataCache[key] = normalizedData;
        results.push({ key, data: normalizedData });
      } catch (error) {
        console.error(`Error processing ${key} data:`, error);
        // Continue with other characters
      }
    }
    
    // Convert array of results to object with character keys
    return results.reduce((acc, { key, data }) => {
      acc[key] = data;
      return acc;
    }, {});
  } catch (error) {
    console.error('Error loading character data:', error);
    return {};
  }
};

/**
 * Normalizes raw character JSON data into a cleaner, more accessible format
 * @param {Object} rawData - Raw character data from JSON
 * @returns {Object} - Normalized character data
 */
function normalizeCharacterData(rawData) {
  if (!rawData) return null;
  
  try {
    // Extract basic character info
    const { name, system, img } = rawData;
    
    // Normalize ability scores
    const abilities = Object.entries(system.abilities || {}).reduce((acc, [key, data]) => {
      acc[key] = {
        value: data.value || 0,
        modifier: Math.floor((data.value - 10) / 2),
        proficient: data.proficient === 1,
      };
      return acc;
    }, {});
    
    // Normalize skills
    const skills = Object.entries(system.skills || {}).reduce((acc, [key, data]) => {
      // Only include skills that the character is proficient in
      if (data.value > 0) {
        acc[key] = {
          value: data.value,
          ability: data.ability,
          proficient: data.value > 0,
        };
      }
      return acc;
    }, {});
    
    // Map skill codes to human-readable names
    const skillNames = {
      acr: 'Acrobatics',
      ani: 'Animal Handling',
      arc: 'Arcana',
      ath: 'Athletics',
      dec: 'Deception',
      his: 'History',
      ins: 'Insight',
      itm: 'Intimidation',
      inv: 'Investigation',
      med: 'Medicine',
      nat: 'Nature',
      prc: 'Perception',
      prf: 'Performance',
      per: 'Persuasion',
      rel: 'Religion',
      slt: 'Sleight of Hand',
      ste: 'Stealth',
      sur: 'Survival'
    };
    
    // Get proficient skills as an array of readable names
    const proficientSkills = Object.keys(skills).map(key => skillNames[key]).filter(Boolean);
    
    // Extract other useful character data
    // These fields may or may not exist in all character JSONs
    const items = system.items || [];
    const spells = system.spells || {};
    const currency = system.currency || {};
    const traits = system.traits || {};
    const details = system.details || {};
    
    // Extract HP information
    const hp = {
      value: system.attributes?.hp?.value || 0,
      max: system.attributes?.hp?.max || 0,
      temp: system.attributes?.hp?.temp || 0
    };
    
    // Assemble the normalized character data
    return {
      name,
      img,
      abilities,
      skills,
      proficientSkills,
      hp,
      currency,
      items,
      spells,
      traits,
      details,
      
      // Include some helper methods on each character
      getAbilityScore: (abilityKey) => abilities[abilityKey]?.value || 0,
      getAbilityModifier: (abilityKey) => abilities[abilityKey]?.modifier || 0,
      isProficientInSkill: (skillKey) => !!skills[skillKey]?.proficient,
      
      // Keep the original data for advanced use cases
      rawData
    };
  } catch (error) {
    console.error('Error normalizing character data:', error);
    return null;
  }
}

/**
 * Gets ability score information for a character
 * @param {string} characterKey - The character identifier
 * @param {string} abilityKey - The ability key (str, dex, etc.)
 * @returns {Object|null} - Ability score data or null if not found
 */
export const getCharacterAbility = async (characterKey, abilityKey) => {
  try {
    const data = await loadCharacterData(characterKey);
    return data?.abilities?.[abilityKey] || null;
  } catch (error) {
    console.error(`Error getting ability ${abilityKey} for ${characterKey}:`, error);
    return null;
  }
};

/**
 * Gets all proficient skills for a character
 * @param {string} characterKey - The character identifier
 * @returns {Array<string>} - Array of skill names
 */
export const getCharacterProficientSkills = async (characterKey) => {
  try {
    const data = await loadCharacterData(characterKey);
    return data?.proficientSkills || [];
  } catch (error) {
    console.error(`Error getting proficient skills for ${characterKey}:`, error);
    return [];
  }
};

/**
 * Utility to map party members to their data files
 */
export const PARTY_MEMBERS = [
  {
    id: 1,
    name: 'Ching Pow',
    level: 3,
    class: 'Monk',
    subclass: 'Ascendant Dragon',
    race: 'Topaz Gem Dragonborn',
    hp: 27,
    veiledPath: 'Unbound',
    hasPathPage: true,
    jsonKey: 'chingPow'
  },
  {
    id: 2,
    name: 'Grumbus',
    level: 3,
    class: 'Sorcerer',
    subclass: 'Wild Magic',
    race: 'Lifedrinker',
    hp: 18,
    veiledPath: 'Unchoosen',
    hasPathPage: false,
    jsonKey: 'korben'
  },
  {
    id: 3,
    name: 'Orny Turlcise',
    level: 3,
    class: 'Druid',
    subclass: 'Circle of the Glacial Heart',
    race: 'Firbolg',
    hp: 33,
    veiledPath: 'Unchoosen',
    hasPathPage: false,
    jsonKey: 'orny'
  },
  {
    id: 4,
    name: 'Tex Avery',
    level: 3,
    class: 'Fighter',
    subclass: 'Gunslinger',
    race: 'Humanity',
    hp: 36,
    veiledPath: 'Unbound',
    hasPathPage: true,
    jsonKey: 'tex'
  }
];

/**
 * React hook for using character data in components
 * @returns {Object} - Character data and loading state
 */
export const useCharacterData = () => {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  
  React.useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await loadCharacterData();
        
        // Check if any characters were successfully loaded
        const hasAnyCharacterData = Object.keys(result).length > 0;
        
        if (!hasAnyCharacterData) {
          throw new Error('No character data could be loaded.');
        }
        
        if (isMounted) {
          setData(result);
          setError(null);
        }
      } catch (err) {
        console.error('Error in useCharacterData hook:', err);
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    
    fetchData();
    
    return () => {
      isMounted = false;
    };
  }, []);
  
  return { data, loading, error };
}; 