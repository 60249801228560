import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import unboundImage from '../../assets/images/deities/unbound.jpg';
import './DeityPathPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const glow = keyframes`
  0%, 100% { text-shadow: 0 0 30px #ffd700, 0 0 50px #ffd700, 0 0 70px #ffd700; }
  50% { text-shadow: 0 0 20px #ffd700, 0 0 35px #ffd700, 0 0 50px #ffd700; }
`;

const StyledUnboundPage = styled.div`
  .deity-path-title h1 {
    font-size: 4rem;
    color: #ffd700;
    text-transform: uppercase;
    letter-spacing: 4px;
    animation: ${glow} 3s ease-in-out infinite;
    position: relative;
    z-index: 1;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, rgba(255, 215, 0, 0.2), transparent);
      background-size: 1000px 100%;
      animation: ${shimmer} 5s infinite linear;
      z-index: -1;
    }
  }

  .content-block, .trait {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(255, 215, 0, 0.3);
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.1);
    transition: all 0.3s ease;
    margin: 2rem 0;
    padding: 2rem;

    &:hover {
      border-color: rgba(255, 215, 0, 0.6);
      box-shadow: 0 0 30px rgba(255, 215, 0, 0.2);
      transform: translateY(-2px);
    }
  }

  h2 {
    color: #ffd700;
    font-size: 2rem;
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
    margin-bottom: 1rem;
  }

  h3 {
    color: #ffd700;
    font-size: 1.5rem;
    text-shadow: 0 0 8px rgba(255, 215, 0, 0.4);
    margin: 1rem 0;
  }

  p {
    color: #fff;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  }

  strong {
    color: #ffd700;
    text-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
    font-weight: bold;
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: #ffd700;
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 0 0 10px #ffd700;
    }
  }
`;

const UnboundPath = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // The sequence of pages for navigation - following dropdown menu order
  const pageSequence = [
    '/deities/caelumbris/shadowed',
    '/deities/firstborn/soulbound',
    '/deities/libris/knowledge',
    '/deities/nebula/mistwalker',
    '/deities/queenmab/frozenveil',
    '/deities/queen-titania/wildgrowth',
    '/deities/sacrathor/dominion',
    '/deities/unbound',
    '/deities/valdis/justice',
    '/deities/wildscar/emberfury'
  ];

  // Required minimum distance traveled to be considered a swipe
  const minSwipeDistance = 50;

  // Handle navigation based on swipe direction
  const handleSwipe = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    // Get current page index in the sequence
    const currentIndex = pageSequence.findIndex(page => page === location.pathname);
    
    // Navigate based on swipe direction
    if (isLeftSwipe) {
      // Navigate to next page (or first if at end)
      const nextIndex = (currentIndex + 1) % pageSequence.length;
      navigate(pageSequence[nextIndex]);
    } else if (isRightSwipe) {
      // Navigate to previous page (or last if at first)
      const prevIndex = (currentIndex - 1 + pageSequence.length) % pageSequence.length;
      navigate(pageSequence[prevIndex]);
    }
  };

  // Touch event handlers
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
  };

  // Mouse event handlers for desktop testing
  const onMouseDown = (e) => {
    setTouchEnd(null);
    setTouchStart(e.clientX);
  };

  const onMouseMove = (e) => {
    if (!touchStart) return;
    setTouchEnd(e.clientX);
  };

  const onMouseUp = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
    setTouchStart(null);
    setTouchEnd(null);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return (
    <StyledUnboundPage 
      className="deity-path-page"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <div className="deity-path-hero">
        <img 
          src={unboundImage} 
          alt="Unbound" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>The Unbound</h1>
        </div>
      </div>

      <div className="content-block requirements-section">
        <div className="edicts">
          <h2>Mythic Path: The Way of the Unbound</h2>
          <p>
            This is a path of those who refuse to rely on the gifts of higher beings. The gods make their offers, whispering of power and purpose, but you do not answer. You walk a different road, one built on determination, mastery, and the ability to shape the world by your own hands. Where others wield borrowed strength, yours is earned. Where others seek guidance, you carve your own way.
          </p>
          <p>
            The <strong>Unbound</strong> do not rise through worship or submission. They ascend through <strong>sheer willpower</strong>, refining their abilities beyond mortal constraints. Their strength is their own, their path is theirs alone to walk.
          </p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Level 3: Awakening of Potential</h3>
        </div>
        <p>The first step in your journey toward mastery. Your body and mind sharpen, reacting to threats with precision and endurance beyond the ordinary.</p>

        <div className="trait">
          <div className="trait-header">
            <h3>Survivor's Instinct</h3>
          </div>
          <p>Once per day, if reduced to 0 Hit Points, you automatically stabilize. Additionally, you may take one final action before falling unconscious on the following turn when brought below 0 Hit Points.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Tactical Precision</h3>
          </div>
          <p>Your awareness of battle is honed to a razor's edge. You gain a bonus to initiative equal to your proficiency bonus, ensuring that you act swiftly and decisively.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Perfect Restoration</h3>
          </div>
          <p>When you complete a long rest, you restore all Hit Points regardless of conditions. No affliction, exhaustion, or injury can keep you from rising again, stronger than before.</p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Level 5: Pinnacle of Growth</h3>
        </div>
        <p>At this stage, your power begins to manifest in ways beyond mortal understanding. Your body, mind, and soul ascend toward a perfected state.</p>
        
        <div className="trait">
          <div className="trait-header">
            <h3>Limitless Potential</h3>
          </div>
          <p>You gain <strong>+4 to all ability scores</strong>. This raw enhancement makes you superior in every aspect, your might unmatched by any who still rely on external forces.</p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Level 10: Master of Fate</h3>
        </div>
        <p>With mastery over yourself comes mastery over the world. You no longer move with hesitation or doubt—your will dictates your success.</p>

        <div className="trait">
          <div className="trait-header">
            <h3>Unbreakable Will</h3>
          </div>
          <p>No force can shake your resolve. You add your proficiency bonus to all saving throws, making you an unshakable force against magic, manipulation, and death itself.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Universal Mastery</h3>
          </div>
          <p>No skill is beyond your reach. You gain proficiency in all skills, ensuring that no task is too difficult for one who has honed themselves to perfection.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Guided Success</h3>
          </div>
          <p>A number of times per day equal to your proficiency bonus, you may increase a d20 roll by your proficiency bonus before knowing the result, shaping reality itself to your advantage.</p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Level 15: Transcendence of Self</h3>
        </div>
        <p>You are no longer simply mortal. Your mastery of self and existence elevates you beyond the constraints of those still bound by fate.</p>

        <div className="trait">
          <div className="trait-header">
            <h3>Ultimate Form</h3>
          </div>
          <p>You gain <strong>+2 to all ability scores</strong> (for a total of +6 across all levels). Your body and mind have reached perfection, making you superior to any being that still clings to lesser ideals.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Existence Unbound</h3>
          </div>
          <p>No longer affected by mortal frailties, you no longer require food, sleep, or air to survive. Your form sustains itself through sheer willpower alone.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Predatory Instinct</h3>
          </div>
          <p>You strike with absolute confidence and precision. The first attack roll you make against each creature in combat has advantage, ensuring that you always set the tempo of battle.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Absolute Control</h3>
          </div>
          <p>A number of times per day equal to your proficiency bonus, you may roll any d20 with advantage before knowing the result, ensuring that your will is never denied.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Enduring Presence</h3>
          </div>
          <p>At the start of each of your turns, you gain temporary Hit Points equal to your proficiency bonus. This constant regeneration makes you nearly impossible to wear down.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Surpassing Limits</h3>
          </div>
          <p>You have shattered the last remaining barriers of constraint. Your movement speed doubles, and once per turn, you may take an additional action (Attack, Dash, Disengage, or Use an Object), moving faster and acting with more precision than any other being.</p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Path Summary</h3>
        </div>
        <p>
          The <strong>Way of the Unbound</strong> is a testament to personal mastery, relying on <strong>one's own abilities rather than the gifts of external forces.</strong> Unlike other paths tied to divine patrons, this path represents the apex of <strong>self-discipline and refinement</strong>—a being who has pushed beyond natural limits and shaped their own destiny through sheer dedication.
        </p>
        <p>
          At their pinnacle, the Unbound move <strong>faster than thought, act before their enemies can react, and impose their will upon reality.</strong> They are the architects of their own strength, carving their legend with <strong>raw ability and unshakable conviction.</strong>
        </p>
      </div>

      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={unboundImage} 
              alt="Unbound"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={unboundImage}
              download="Unbound.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </StyledUnboundPage>
  );
};

export default UnboundPath;